import React, {useState, useEffect, useRef} from 'react';
import axios from 'axios';
import '../config.js';
import { theme } from '../services/helper.js';
import { alertService } from '../services/alert.service.js';
import Progress from '../components/progress.js';

function SearchInvoice(props) {
  const [invoice, setInvoice] = useState(null);
  const [invoices, setInvoices] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(()=>{
    if(props.client){
      fetchInvoices();
    }
  }, [props.client]);

  const fetchInvoices = () => {
    setLoading(true);
    axios.get(global.config.api_server+'invoices?client='+props.client+'&token='+localStorage.getItem("token"))
    .then(res => {
      if(res.status === 200){
        setLoading(false);
        const invoices = res.data.invoices;
        if(invoices === null || invoices === undefined){
          alertService.error("Error fetching invoices");
        }else{
          setInvoices(invoices);
        }
      }
    }).catch(err => {
        setLoading(false);
        alertService.error(err);
    });
  }

  const updateInvoice = (id) =>{
    for(let i = 0;i < invoices.length;i++){
        if(invoices[i].invoice_id == id){
            setInvoice(invoices[i]);
            props.handleSubmit(invoices[i]);
            break;
        }
    }
  }
  
  if(loading){
    return <Progress label='Fetching invoices'/>;
  }else{
    return(
      <div>
        <label>Invoice(s): {invoices.length <= 0 ? <span>No Invoice found</span> : <span>click to select</span>}</label>
        <div className="row">
            {invoices.map(inv =>
              <div className="col-md-3" key={inv.invoice_id}>
                <div className={'card clip-card clip-card-'+theme() + (invoice && invoice.invoice_id === inv.invoice_id ? ' selected' : '')} onClick={(e) => updateInvoice(inv.invoice_id)}>
                  <div class="card-body">
                    <h5 class="card-title">Proforma Invoice Id: {inv.invoice_id}</h5>
                    <p class="card-text">Total Amount: {inv.amount}</p>
                    <p class="card-text">Date: {inv.creation_date}</p>
                    <p class="card-text">Balance: {inv.amount - inv.paid}</p>
                  </div>
                </div>
              </div>
            )}
        </div>
        <hr/>
      </div>
    )
  }
}

export default SearchInvoice;