import React from 'react';
import {Link} from 'react-router-dom';
import axios from 'axios';
import '../config.js';
import { theme } from '../services/helper.js';
import { alertService } from '../services/alert.service.js';

function InvoiceListItem(props) {

  return(
        <tr>
          <td>{props.info.invoice_id}</td>
          <td width="40%">{props.info.description}</td>
          <td>{props.info.pax}</td>
          <td>{props.info.amount}</td>
          <td>{props.info.paid}</td>
          <td>{props.info.balance}</td>
          <td>{props.info.updation_date}</td>
          <td>
            {props.type == 'all' && 
              <Link to={"/client/"+props.info.client+"/invoice"} className={'btn btn-icon-split mr-2 btn-'+theme()}>
                <span className="icon text-white-50">
                  <i className="fas fa-link"></i>
                </span>
                <span className="text">Client</span>
              </Link>
            }
            <Link to={"/invoice/"+props.info.invoice_id+"/"+props.type} className={'btn btn-icon-split mr-2 btn-'+theme()}>
              <span className="icon text-white-50">
                <i className="fas fa-edit"></i>
              </span>
            </Link>
          </td>
        </tr>
  )
}

export default InvoiceListItem;