import React from 'react';
import { Link } from 'react-router-dom';
import { theme } from '../services/helper.js';

function Navigation() {
  const userRole = localStorage.getItem("role");
  if(userRole > 1){
    return(
      <ul className={'navbar-nav sidebar sidebar-dark accordion bg-gradient-'+theme()} id="accordionSidebar">
        <Link className="sidebar-brand d-flex align-items-center justify-content-center" to="/">
        <div className="sidebar-brand-icon">
          <img src={global.config.asset_server +"/imgs/login.png"}/>
        </div>
        <div className="sidebar-brand-text mx-3">UAS Billing Platform</div>
        </Link>
        <hr className="sidebar-divider my-0"/>
        <li className="nav-item">
          <Link className="nav-link" to="/"><i className="fas fa-fw fa-tachometer-alt"></i>Dashboard</Link> 
        </li>
        <hr className="sidebar-divider"/>
        <div className="sidebar-heading">
          Administrator
        </div>
        <li className="nav-item">
          <Link className="nav-link" to="/users"><i className="fas fa-fw fa-id-badge"></i>Users</Link> 
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/clients"><i className="fas fa-fw fa-users"></i>Clients</Link> 
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/invoices"><i className="fas fa-fw fa-clipboard"></i>Proforma Invoices</Link> 
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/receipts"><i className="fas fa-fw fa-file"></i>Receipts</Link> 
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/credits"><i className="fas fa-fw fa-credit-card"></i>Credit Notes</Link> 
        </li>
        <hr className="sidebar-divider d-none d-md-block"/>
        <li className="nav-item">
          <Link className="nav-link" to="/festivals"><i className="fas fa-fw fa-magic"></i>Festivals</Link> 
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/template/mail"><i className="fas fa-fw fa-envelope"></i>Mail Templates</Link> 
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/template/sms"><i className="fas fa-fw fa-mobile"></i>SMS Templates</Link> 
        </li>
        {userRole == 10 && 
          <li className="nav-item">
            <Link className="nav-link" to="/settings"><i className="fas fa-fw fa-cogs"></i>Settings</Link> 
          </li>
        }
        <hr className="sidebar-divider d-none d-md-block"/>
          <li className="nav-item">
            <Link className="nav-link" to="/export"><i className="fas fa-fw fa-download"></i>Export</Link> 
          </li>
        <hr className="sidebar-divider d-none d-md-block"/>

        <div className="text-center d-none d-md-inline">
          <button className="rounded-circle border-0" id="sidebarToggle"></button>
        </div>
      </ul>
    )
  }else{
    return null;
  }
}

export default Navigation;