import React from 'react';
import {Link} from 'react-router-dom';
import '../config.js';

import { theme } from '../services/helper.js';

function FestivalListItem(props) {
  let showcase = "No attachment found";
  if(props.info.uri){
    showcase = <a href={global.config.asset_server + props.info.uri} className={'btn btn-icon-split btn-'+theme()}>
                  <span className="icon text-white-50">
                    <i className="fas fa-download"></i>
                  </span>
                  <span className="text">Download</span>
                </a>
    if(props.info.uri && (props.info.uri.endsWith('.jpg') || props.info.uri.endsWith('.jpeg') || props.info.uri.endsWith('.JPG') || props.info.uri.endsWith('.JPEG') || props.info.uri.endsWith('.png') || props.info.uri.endsWith('.PNG') || props.info.uri.endsWith('.gif') || props.info.uri.endsWith('.GIF'))){
      showcase = <a href={props.info.uri}>
                    <img src={global.config.asset_server + props.info.uri} width="auto" height="100px"/>
                  </a>
    }
  }
  return(
        <tr>
          <td>{props.info.subject}</td>
          <td>{props.info.festival_date}</td>
          <td>{props.info.status}</td>
          <td>{showcase}</td>
          <td>
            <Link to={"/festival/"+props.info.festival_id} className={'btn btn-icon-split btn-'+theme()}>
              <span className="icon text-white-50">
                <i className="fas fa-edit"></i>
              </span>
              <span className="text">Edit</span>
            </Link>
          </td>
        </tr>
  )
}

export default FestivalListItem;