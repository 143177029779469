module.exports = global.config = {
    api_server: "https://billing.uasinternationalmis.com/v1/",
    asset_server: "https://billing.uasinternationalmis.com",
    //api_server: "http://localhost/v1/",
    //asset_server: "http://localhost",
    docType: "Photo,Pan Card,Passport Front,Passport Back,Visa,Aadhar Card,Visa Photo,Other",
    clientType: "FIT,CORPORATE,GIP",
    status: "Active,In-Active",
    creditStatus: "Open,Expired,Redeemed",
    salutations: "Mr.,Mrs.,Master,Miss,Ms.,Mx.,Dr.",
    items_per_page: 15,
    settings_token: "mail_cc,mail_bcc,mail_admin,sms_cc,sms_admin,greetings_mail_cc,greetings_sms_cc",
    variable_list_festival: "{client_name},{client_name_with_salutation}",
    variable_list: {
        mail_create_invoice: "{client_name},{client_name_with_salutation},{invoice_number},{amount}",
        mail_create_receipt: "{client_name},{client_name_with_salutation},{receipt_number},{amount}",
        mail_create_credit: "{client_name},{client_name_with_salutation},{credit_note_number},{amount}",
        mail_login_otp: "{otp},{user_info}",
        mail_permission: "{otp},{description},{employee_detail}",
        mail_birthday: "{client_name},{client_name_with_salutation}",
        mail_anniversary: "{client_name},{client_name_with_salutation}",
        mail_export: "{employee_detail},{export_detail},{ip}",
        sms_create_invoice: "{client_name},{client_name_with_salutation},{invoice_number},{amount}",
        sms_create_receipt: "{client_name},{client_name_with_salutation},{receipt_number},{amount}",
        sms_create_credit: "{client_name},{client_name_with_salutation},{credit_note_number},{amount}",
        sms_login_otp: "{otp},{user_info}",
        sms_permission: "{otp},{description},{employee_detail}",
        sms_birthday: "{client_name},{client_name_with_salutation}",
        sms_anniversary: "{client_name},{client_name_with_salutation}",
        sms_export: "{employee_detail},{export_detail},{ip}"
    }
};