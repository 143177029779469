import React, {useState, useEffect, useRef} from 'react';
import axios from 'axios';
import '../config.js';

import { theme, createFormData } from '../services/helper.js';
import { alertService } from '../services/alert.service.js';
import Progress from '../components/progress.js';

function ClientBasicInfo(props){
  const [loading, setLoading] = useState(false);
  const [address, setAddress] = useState(props.client.address);
  const [gender, setGender] = useState(props.client.gender);
  const [salutation, setSalutation] = useState(props.client.salutation);
  const [first_name, setFirstName] = useState(props.client.first_name);
  const [last_name, setLastName] = useState(props.client.last_name);
  const [middle_name, setMiddleName] = useState(props.client.middle_name);
  const [email, setEmail] = useState(props.client.email);
  const [mobile, setMobile] = useState(props.client.mobile);
  const [dob, setDob] = useState(props.client.dob);
  const [type, setType] = useState(props.client.type);
  const [pan, setPan] = useState(props.client.pan);
  const [gst, setGst] = useState(props.client.gst);
  const [city, setCity] = useState(props.client.city);
  const [state, setState] = useState(props.client.state);
  const [pincode, setPincode] = useState(props.client.pincode);
  const [otp, setOtp] = useState(null);
  const [anniversary, setAnniversary] = useState(props.client.anniversary);
  
  const handleSubmit = (event) => {
    event.preventDefault();
    if(first_name && last_name && email && mobile && address && city && state && pincode && pan && (userRole == 10 || otp)){
      setLoading(true);
      axios.post(global.config.api_server+'client',createFormData({
        first_name: first_name,
        last_name: last_name,
        middle_name: middle_name,
        dob: dob,
        anniversary: anniversary,
        email: email,
        mobile: mobile,
        address: address, 
        gender: gender,
        salutation: salutation,
        pan: pan,
        city: city,
        gst: gst,
        state: state,
        pincode: pincode,
        token: localStorage.getItem("token"),
        client_id: props.id,
        task: 'basic',
        type: type,
        otp: otp
      })).then(res => {
        setLoading(false);
        if(res.status === 200 && res.data.status === "success"){
          alertService.success('Client updated');
        }else{
          alertService.error(res.data.error);
        }
      }).catch(err => {
        alertService.error(err);
        setLoading(false);
      });
    }else{
      alertService.error('Some fields are required');
    }
  }

  const reqPermissionOtp = (event) => {
    if(userRole < 10){
      const payloadType = "CLIENT_UPDATE";
      axios.get(global.config.api_server+'permission?payloadType='+payloadType+'&payload='+props.id+'&description='+props.client.email+'&token='+localStorage.getItem("token")).then(res => {
        if(res.status === 200 && res.data.status === "success"){
          alertService.success('OTP Requested');
        }else{
          alertService.error(res.data.error);
        }
      }).catch(err => {
        alertService.error(err);
      });
    }
  }

  const salutations = global.config.salutations.split(",");
  const userRole = localStorage.getItem("role");

  const date = new Date(dob);
  let birth_date = undefined;
  if(date.getFullYear() > 1900){
    birth_date = date.getFullYear() + "-";
    if((date.getMonth() + 1) < 10){
      birth_date += "0";
    }
    birth_date += (date.getMonth() + 1);
    birth_date += "-";
    if(date.getDate() < 10){
      birth_date += "0";
    }
    birth_date += date.getDate();
  }

  const annDate = new Date(anniversary);
  let anniversary_date = undefined;
  if(annDate.getFullYear() > 1900){
    anniversary_date = annDate.getFullYear() + "-";
    if((annDate.getMonth() + 1) < 10){
      anniversary_date += "0";
    }
    anniversary_date += (annDate.getMonth() + 1);
    anniversary_date += "-";
    if(annDate.getDate() < 10){
      anniversary_date += "0";
    }
    anniversary_date += annDate.getDate();
  }
  const clientTypes = global.config.clientType.split(",");
  return(
    <div className="card shadow mb-4">
      <div className="card-header py-3">
        {userRole == 10 &&        
          <button onClick={(e) => handleSubmit(e)} className={'btn btn-icon-split float-right btn-'+theme()}>
            <span className="icon text-white-50">
              <i className="fas fa-save"></i>
            </span>
            <span className="text">Save</span>
          </button>
        }
        {userRole != 10 && 
          <button className={'btn btn-icon-split float-right btn-'+theme()} data-toggle="modal" data-target="#updateClientModal" onClick={reqPermissionOtp}>
            <span className="icon text-white-50">
              <i className="fas fa-save"></i>
            </span>
            <span className="text">Save</span>
          </button>
        }
        <h6 className={'mt-2 font-weight-bold text-'+theme()}>Client - Basic Information</h6>
      </div>
      <div className="card-body">
        <div className="row">
          {loading === true ? (
            <Progress label='Saving' small={true}/>
          ):null}
          <div className="col-md-6">
            <div className="form-group">
              <label>Salutation *</label>
              <select name="salutation" className="form-control" value={salutation} id="salutation" onChange={(e) => setSalutation(e.target.value)}>
                {salutations.map(d =>
                    <option value={d} key={d}>{d}</option>
                )}
              </select>
            </div>
            <div className="form-group">
              <label>First Name *</label>
              <input type="text" value={first_name} maxLength="50" name="first_name" className="form-control" id="first_name" placeholder="Enter First Name" onChange={(e) => setFirstName(e.target.value)}/>
            </div>
            <div className="form-group">
              <label>Middle Name</label>
              <input type="text" value={middle_name} maxLength="50" name="middle_name" className="form-control" id="middle_name" placeholder="Enter Middle Name" onChange={(e) => setMiddleName(e.target.value)}/>
            </div>
            <div className="form-group">
              <label>Last Name *</label>
              <input type="text" value={last_name} maxLength="50" name="last_name" className="form-control" id="last_name" placeholder="Enter Last Name" onChange={(e) => setLastName(e.target.value)}/>
            </div>
            <div className="form-group">
              <label>Email *</label>
              <input type="email" value={email} maxLength="50" name="email" className="form-control" id="email" placeholder="Enter Email" onChange={(e) => setEmail(e.target.value)}/>
            </div>
            <div className="form-group">
              <label>Type*</label>
              <select name="type" className="form-control" id="type" value={type} onChange={(e) => setType(e.target.value)}>
                {clientTypes.map(d =>
                    <option value={d} key={d}>{d}</option>
                )}
              </select>
            </div>
            <div className="form-group">
              <label>PAN *</label>
              <input type="text" value={pan} maxLength="20" name="pan" className="form-control" id="pan" placeholder="Enter PAN" onChange={(e) => setPan(e.target.value)}/>
            </div>
            <div className="form-group">
              <label>GST</label>
              <input type="text" value={gst} maxLength="50" name="gst" className="form-control" id="gst" placeholder="Enter GST" onChange={(e) => setGst(e.target.value)}/>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label>Mobile *</label>
              <input type="text" value={mobile} maxLength="15" name="mobile" className="form-control" id="mobile" placeholder="Enter Mobile" onChange={(e) => setMobile(e.target.value)}/>
            </div>
            <div className="form-group">
              <label>Gender</label>
              <select name="gender" className="form-control" value={gender} id="gender" onChange={(e) => setGender(e.target.value)}>
                <option>--Select--</option>
                <option value="1">Male</option>
                <option value="0">Female</option>
              </select>
            </div>
            <div className="form-group">
              <label>Date of Birth</label>
              <input type="date" value={birth_date} maxLength="50" name="dob" className="form-control" id="dob" placeholder="Enter DOB" onChange={(e) => setDob(e.target.value)}/>
            </div>
            <div className="form-group">
              <label>Anniversary</label>
              <input type="date" value={anniversary_date} maxLength="50" name="anniversary" className="form-control" id="anniversary" placeholder="Enter DOB" onChange={(e) => setAnniversary(e.target.value)}/>
            </div>
            <div className="form-group">
              <label>City</label>
              <input type="text" value={city} maxLength="50" name="city" className="form-control" id="city" placeholder="Enter City" onChange={(e) => setCity(e.target.value)}/>
            </div>
            <div className="form-group">
              <label>State</label>
              <input type="text" value={state} maxLength="50" name="state" className="form-control" id="state" placeholder="Enter State" onChange={(e) => setState(e.target.value)}/>
            </div>
            <div className="form-group">
              <label>Pincode</label>
              <input type="text" value={pincode} maxLength="50" name="pincode" className="form-control" id="pincode" placeholder="Enter Pincode" onChange={(e) => setPincode(e.target.value)}/>
            </div>
            <div className="form-group">
              <label>Address</label>
              <textarea value={address} maxLength="200" name="address" className="form-control" id="address" placeholder="Enter Address" onChange={(e) => setAddress(e.target.value)}/>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <hr/>
            <div className="form-group">
              <label><strong>Stats</strong></label>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label>Updated</label>
              <input type="text" disabled={true} value={props.client.updation_date} className="form-control"/>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label>Created</label>
              <input type="text" disabled={true} value={props.client.creation_date} className="form-control"/>
            </div>
          </div>
        </div>
      </div>
      {userRole != 10 && 
        <div className="modal fade" id="updateClientModal" tabIndex="-1" role="dialog" aria-labelledby="update-client-popup" aria-hidden="true">
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="update-client-popup">Updating Client</h5>
                <button className="close" type="button" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="form-group">
                  <label>Enter OTP to continue.</label>
                  <input type="text" maxLength="50" name="otp" value={otp} className="form-control" id="otp" placeholder="Enter OTP" onChange={(e) => setOtp(e.target.value)}/>
                </div>
              </div>
              <div className="modal-footer">
                <button className="btn btn-default" type="button" data-dismiss="modal">Cancel</button>
                <button className={'btn ml-3 btn-'+theme()} onClick={(e) => handleSubmit(e)}  data-dismiss="modal">Save</button>
              </div>
            </div>
          </div>
        </div>
        }
    </div>
  )
}

export default ClientBasicInfo;