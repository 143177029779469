import React, {useState, useEffect, useRef} from 'react';
import axios from 'axios';
import '../config.js';
import { theme } from '../services/helper.js';
import { alertService } from '../services/alert.service.js';
import Progress from '../components/progress.js';

function SearchClient(props) {
  const [client, setClient] = useState(null);
  const [clients, setClients] = useState([]);
  const [searchClients, setSearchClients] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(()=>{
    fetchClients();
  }, []);

  const fetchClients = () => {
    setLoading(true);
    axios.get(global.config.api_server+'clients?token='+localStorage.getItem("token"))
    .then(res => {
      if(res.status === 200){
        setLoading(false);
        const clients = res.data.clients;
        if(clients === null || clients === undefined){
          alertService.error("Error fetching clients");
        }else{
          setClients(clients);
        }
      }
    }).catch(err => {
        setLoading(false);
        alertService.error(err);
    });
  }

  const handleClientChange = (event) => {
    const target = event.target;
    const value = target.value;
    let searchedClients = [];
    let counter = 0;
    for(let i = 0;i < clients.length;i++){
      if(value.trim() != "" && (clients[i].first_name.indexOf(value) >= 0 || clients[i].middle_name.indexOf(value) >= 0 || clients[i].last_name.indexOf(value) >= 0 || clients[i].email.indexOf(value) >= 0 || clients[i].mobile.indexOf(value) >= 0)){
        searchedClients.push(clients[i]);
        counter++;
      }
      if(counter == 5){
        break;
      }
    }
    setSearchClients(searchedClients);
  }

  const updateClient = (id) =>{
    for(let i = 0;i < clients.length;i++){
        if(clients[i].client_id == id){
            setClient(clients[i]);
            props.handleSubmit(clients[i]);
            break;
        }
    }
  }
  
  if(loading){
    return <Progress/>;
  }else{
    return(
        <div className="row">
            <div className="col-md-6">
                <div className="form-group">
                    <label>Search Client</label>
                    <input type="text" maxLength="15" className="form-control" placeholder="Search Client" onChange={(e) => handleClientChange(e)}/>
                    {client &&
                      <div>
                      <hr/>
                      <label>Selected Client</label>
                      <input type="text" disabled={true}  className="form-control" value={client.client_id + " | " + client.email + " | " + client.mobile}/>
                      </div>
                    }
                </div>
            </div>
            <div className="col-md-6">
            {searchClients.map(searchClient =>
                <div className={'card clip-card clip-card-'+theme() + (client && client.client_id === searchClient.client_id ? ' selected' : '')} key={searchClient.client_id} onClick={(e) => updateClient(searchClient.client_id)}>
                    <div class="card-body">
                      <p class="card-text">{searchClient.first_name} {searchClient.middle_name} {searchClient.last_name} ({searchClient.email} | {searchClient.mobile})</p>
                    </div>
                </div>
            )}
            </div>
            <div className="col-md-12"><hr/></div>
        </div>
    )
  }
}

export default SearchClient;