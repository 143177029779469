import React, {useState, useEffect, useRef} from 'react';
import { useParams } from 'react-router';
import {Link, useNavigate  } from 'react-router-dom';
import axios from 'axios';
import '../config.js';

import { theme } from '../services/helper.js';
import { alertService } from '../services/alert.service.js';
import Progress from '../components/progress.js';
import ReceiptBasicInfo from '../components/receiptBasicInfo.js';

function Receipt(props) {
  const { id, subnav } = useParams();
  const [receipt, setReceipt] = useState({});
  const [loading, setLoading] = useState(true);
  const [otp, setOtp] = useState(null);
  const navigate = useNavigate();
  const userRole = localStorage.getItem("role");

  useEffect(()=>{
    axios.get(global.config.api_server+'receipt?id='+id+'&token='+localStorage.getItem("token")) 
    .then(res => {
      if(res.status === 200 && res.data.status === "success"){
        setReceipt(res.data.receipt);
        setLoading(false);
      }else{
        alertService.error(res.data.error);
      }
    }).catch(err => {
      alertService.error(err);
      navigate("/receipts");
    });
  }, []);
  
  const handleDelete = (event) => {
    event.preventDefault();
    if(id && (otp || userRole == 10)){
      axios.get(global.config.api_server+'receipt?id='+id+'&task=delete&otp='+otp+'&token='+localStorage.getItem("token")).then(res => {
        if(res.status === 200 && res.data.status === "success"){
          alertService.success('Receipt deleted');
          navigateBack();
        }else{
          alertService.error(res.data.error);
        }
      }).catch(err => {
        alertService.error(err);
      });
    }else{
      alertService.error('Invalid Input');
    }
  }

  const reqPermissionOtp = (event) => {
    if(userRole < 10){
      const payloadType = "RECEIPT_DELETE";
      axios.get(global.config.api_server+'permission?payloadType='+payloadType+'&payload='+id+'&description='+receipt.receipt_id+" of "+receipt.client_info.email+'&token='+localStorage.getItem("token")).then(res => {
        if(res.status === 200 && res.data.status === "success"){
          alertService.success('OTP Requested');
        }else{
          alertService.error(res.data.error);
        }
      }).catch(err => {
        alertService.error(err);
      });
    }
  }

  const navigateBack = () => {
    if(subnav == "all"){
      navigate("/receipts");
    }else{
      navigate("/client/"+receipt.client+"/receipt");
    }
  }

  if(loading){
    return <Progress/>;
  }else{
    return(
      <div className="container-fluid">
        <div className="mb-3">
          <button className={'btn btn-icon-split float-right btn-'+theme()} data-toggle="modal" data-target="#deleteReceiptModal" onClick={reqPermissionOtp}>
            <span className="icon text-white-50">
              <i className="fas fa-trash"></i>
            </span>
            <span className="text">Delete</span>
          </button>
          <button onClick={(e) => navigateBack(e)} className="btn btn-light btn-icon-split mr-2">
            <span className="icon text-white-50">
              <i className="fas fa-arrow-left"></i>
            </span>
            <span className="text">Back</span>
          </button>
        </div>
        
        <ReceiptBasicInfo id={id} receipt={receipt}></ReceiptBasicInfo>
        
        <div className="modal fade" id="deleteReceiptModal" tabIndex="-1" role="dialog" aria-labelledby="delete-receipt-popup" aria-hidden="true">
          <div className="modal-dialog modal-xl" role="document">
          {userRole == 10 && 
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="delete-receipt-popup">Confirm deletion?</h5>
                <button className="close" type="button" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">Are you sure, you want to delete this receipt?</div>
              <div className="modal-footer">
                <button className="btn btn-default" type="button" data-dismiss="modal">Cancel</button>
                <button className={'btn ml-3 btn-'+theme()} onClick={handleDelete} data-dismiss="modal">Delete</button>
              </div>
            </div>
          }
          {userRole != 10 && 
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="delete-popup">Confirm deletion?</h5>
                <button className="close" type="button" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="form-group">
                  <label>Are you sure, you want to delete this receipt? Enter OTP to continue.</label>
                  <input type="text" maxLength="50" name="otp" value={otp} className="form-control" id="otp" placeholder="Enter OTP" onChange={(e) => setOtp(e.target.value)}/>
                </div>
              </div>
              <div className="modal-footer">
                <button className="btn btn-default" type="button" data-dismiss="modal">Cancel</button>
                <button className={'btn ml-3 btn-'+theme()} onClick={handleDelete} data-dismiss="modal">Delete</button>
              </div>
            </div>
          }
          </div>
        </div>
      </div>
    )
  }
}

export default Receipt;