export function theme(){
  let num = 0;
  if(localStorage.getItem("theme") !== undefined && localStorage.getItem("theme") !== null){
    num = parseInt(localStorage.getItem("theme"));
  }
  if(num === 1){
    return 'success';
  }else if(num === 2){
    return 'info';
  }else if(num === 3){
    return 'warning';
  }else if(num === 4){
    return 'danger';
  }else if(num === 5){
    return 'secondary';
  }else if(num === 6){
    return 'dark';
  }else{
    return 'primary';
  }
}

export function themeColor(){
  let num = 0;
  if(localStorage.getItem("theme") !== undefined && localStorage.getItem("theme") !== null){
    num = parseInt(localStorage.getItem("theme"));
  }
  if(num === 1){
    return '#28a745';
  }else if(num === 2){
    return '#17a2b8';
  }else if(num === 3){
    return '#ffc107';
  }else if(num === 4){
    return '#dc3545';
  }else if(num === 5){
    return '#6c757d';
  }else if(num === 6){
    return '#343a40';
  }else{
    return '#007bff';
  }
}

export function createFormData(object) {
  let formData = '';
  Object.keys(object).forEach(key => formData += key+'='+encodeURI(object[key])+'&');
  return formData.substring(0,formData.length-1);
}

export function createFormData2(object) {
  const formData = new FormData();
  Object.keys(object).forEach(key => formData.append(key, object[key]));
  return formData;
}

export function sortObj(list, key, order, providedType) {
  function compare(a, b) {
      a = a[key];
      b = b[key];
      var type = 'string';
      if(providedType !== undefined && providedType !== null){
        type = providedType;
      }else{
        type = (typeof(a) === 'string' ||
                  typeof(b) === 'string') ? 'string' : 'number';
      }
      var result;
      if(order === false){
        if (type === 'string') result = b.localeCompare(a);
        else result = b - a;
      }else{
        if (type === 'string') result = a.localeCompare(b);
        else result = a - b;
      }
      return result;
  }
  return list.sort(compare);
}