import React, {useState, useEffect, useRef} from 'react';
import { theme } from '../services/helper.js';
import {Link} from 'react-router-dom';
import axios from 'axios';
import { alertService } from '../services/alert.service.js';
import Progress from '../components/progress.js';

function Dasboard() {
    const [dashboard, setDashboard] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(()=>{
        let uri = global.config.api_server+'dashboard?token='+localStorage.getItem("token");
        axios.get(uri)
        .then(res => {
            if(res.status === 200 && res.data.status === "success"){
            const temp = res.data.dashboard;
            setDashboard(temp)
            setLoading(false);
            }
        }).catch(err => {
            alertService.error(err);
        });
    }, []);

    if(loading){
        return <Progress/>;
    }else{
        return(
            <div className="container-fluid">
            <div className="d-sm-flex align-items-center justify-content-between mb-4">
                <h1 className="h3 mb-0 text-gray-800">Dashboard</h1>
            </div>
            <div className="row">
                <div className="col-xl-3 col-md-6 mb-4">
                    <Link to={"/users"}>
                        <div className={"card shadow h-100 py-2 border-left-"+theme()}>
                            <div className="card-body">
                                <div className="row no-gutters align-items-center">
                                    <div className="col mr-2">
                                        <div className={"text-xs font-weight-bold text-uppercase mb-1 text-"+theme()}>
                                            Users (Employee)</div>
                                        <div className="h5 mb-0 font-weight-bold text-gray-800">{dashboard.users.count}</div>
                                    </div>
                                    <div className="col-auto">
                                        <i className="fas fa-id-badge fa-2x text-gray-300"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Link>
                </div>
                <div className="col-xl-3 col-md-6 mb-4">
                    <Link to={"/report/client"}>
                        <div className={"card shadow h-100 py-2 border-left-"+theme()}>
                            <div className="card-body">
                                <div className="row no-gutters align-items-center">
                                    <div className="col mr-2">
                                        <div className={"text-xs font-weight-bold text-uppercase mb-1 text-"+theme()}>
                                            Clients</div>
                                        <div className="h5 mb-0 font-weight-bold text-gray-800">{dashboard.clients.count}</div>
                                    </div>
                                    <div className="col-auto">
                                        <i className="fas fa-users fa-2x text-gray-300"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Link>
                </div>
                <div className="col-xl-3 col-md-6 mb-4">
                    <Link to={"/report/invoice"}>
                        <div className={"card shadow h-100 py-2 border-left-"+theme()}>
                            <div className="card-body">
                                <div className="row no-gutters align-items-center">
                                    <div className="col mr-2">
                                        <div className={"text-xs font-weight-bold text-uppercase mb-1 text-"+theme()}>
                                            Proforma Invoices</div>
                                        <div className="h5 mb-0 font-weight-bold text-gray-800">{dashboard.invoices.count}</div>
                                    </div>
                                    <div className="col-auto">
                                        <i className="fas fa-clipboard fa-2x text-gray-300"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Link>
                </div>
                <div className="col-xl-3 col-md-6 mb-4">
                    <Link to={"/report/receipt"}>
                        <div className={"card shadow h-100 py-2 border-left-"+theme()}>
                            <div className="card-body">
                                <div className="row no-gutters align-items-center">
                                    <div className="col mr-2">
                                        <div className={"text-xs font-weight-bold text-uppercase mb-1 text-"+theme()}>
                                            Receipts</div>
                                        <div className="h5 mb-0 font-weight-bold text-gray-800">{dashboard.receipts.count}</div>
                                    </div>
                                    <div className="col-auto">
                                        <i className="fas fa-file fa-2x text-gray-300"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Link>
                </div>
                <div className="col-xl-3 col-md-6 mb-4">
                    <Link to={"/report/invoice_amount"}>
                        <div className={"card shadow h-100 py-2 border-left-"+theme()}>
                            <div className="card-body">
                                <div className="row no-gutters align-items-center">
                                    <div className="col mr-2">
                                        <div className={"text-xs font-weight-bold text-uppercase mb-1 text-"+theme()}>
                                            Amount of Invoice Generated (This month)</div>
                                        <div className="h5 mb-0 font-weight-bold text-gray-800">{dashboard.monthly_invoice.total}</div>
                                    </div>
                                    <div className="col-auto">
                                        <i className="fas fa-chart-line fa-2x text-gray-300"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Link>
                </div>
                <div className="col-xl-3 col-md-6 mb-4">
                    <Link to={"/report/invoice_amount"}>
                        <div className={"card shadow h-100 py-2 border-left-"+theme()}>
                            <div className="card-body">
                                <div className="row no-gutters align-items-center">
                                    <div className="col mr-2">
                                        <div className={"text-xs font-weight-bold text-uppercase mb-1 text-"+theme()}>
                                            Amount of Invoice Generated (This year)</div>
                                        <div className="h5 mb-0 font-weight-bold text-gray-800">{dashboard.yearly_invoice.total}</div>
                                    </div>
                                    <div className="col-auto">
                                        <i className="fas fa-chart-line fa-2x text-gray-300"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Link>
                </div>
                <div className="col-xl-3 col-md-6 mb-4">
                    <Link to={"/report/receipt_amount"}>
                        <div className={"card shadow h-100 py-2 border-left-"+theme()}>
                            <div className="card-body">
                                <div className="row no-gutters align-items-center">
                                    <div className="col mr-2">
                                        <div className={"text-xs font-weight-bold text-uppercase mb-1 text-"+theme()}>
                                            Amount of Receipt Generated (This month)</div>
                                        <div className="h5 mb-0 font-weight-bold text-gray-800">{dashboard.monthly_receipt.total}</div>
                                    </div>
                                    <div className="col-auto">
                                        <i className="fas fa-chart-line fa-2x text-gray-300"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Link>
                </div>
                <div className="col-xl-3 col-md-6 mb-4">
                    <Link to={"/report/receipt_amount"}>
                        <div className={"card shadow h-100 py-2 border-left-"+theme()}>
                            <div className="card-body">
                                <div className="row no-gutters align-items-center">
                                    <div className="col mr-2">
                                        <div className={"text-xs font-weight-bold text-uppercase mb-1 text-"+theme()}>
                                            Amount of Receipt Generated (This year)</div>
                                        <div className="h5 mb-0 font-weight-bold text-gray-800">{dashboard.yearly_receipt.total}</div>
                                    </div>
                                    <div className="col-auto">
                                        <i className="fas fa-chart-line fa-2x text-gray-300"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Link>
                </div>
                <div className="col-xl-3 col-md-6 mb-4">
                    <Link to={"/report/credit_amount"}>
                        <div className={"card shadow h-100 py-2 border-left-"+theme()}>
                            <div className="card-body">
                                <div className="row no-gutters align-items-center">
                                    <div className="col mr-2">
                                        <div className={"text-xs font-weight-bold text-uppercase mb-1 text-"+theme()}>
                                            Amount of Credit Notes (Open)</div>
                                        <div className="h5 mb-0 font-weight-bold text-gray-800">{dashboard.credits.total}</div>
                                    </div>
                                    <div className="col-auto">
                                        <i className="fas fa-credit-card fa-2x text-gray-300"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Link>
                </div>
            </div>
            </div>
        )
    }
}

export default Dasboard;