import React, {useState, useEffect, useRef} from 'react';
import axios from 'axios';
import '../config.js';

import { theme, sortObj } from '../services/helper.js';
import { alertService } from '../services/alert.service.js';
import CreditListItem from '../components/creditListItem.js';
import Progress from '../components/progress.js';
import { Paginator } from '../components/paginator.js';
import SearchClient from '../components/searchClient.js';
import Sort from '../components/sort.js';
let searchData = {};

function Credits() {
  const [search, setSearch] = useState('');
  const [sortBy, setSortBy] = useState('credit_id');
  const [sortOrder, setSortOrder] = useState(true);
  const [dateSearch, setDateSearch] = useState('none');
  const [filteredCredits, setFilteredCredits] = useState([]);
  const [credits, setCredits] = useState([]);
  const [offset, setOffset] = useState(0);
  const [loading, setLoading] = useState(true);
  const [description, setDescription] = useState('');
  const [notifySms, setNotifySms] = useState(0);
  const [notifyMail, setNotifyMail] = useState(0);
  const [amount, setAmount] = useState(0);
  const [validTill, setValidTill] = useState(Date.now());
  const [remark, setRemark] = useState('');
  const [client, setClient] = useState(null);
  const [archive, setArchive] = useState(0);
  
  let searchForm = useRef();

  const handleSubmit = (event) =>{
    event.preventDefault();
    if(amount && amount > 0 && description && validTill && client){
      const formData = new FormData;
      formData.append('description', description);
      formData.append('remark', remark);
      formData.append('valid_till', validTill);
      formData.append('task', "create");
      formData.append('client', client.client_id);
      formData.append('amount', amount);
      formData.append('notifySms', notifySms);
      formData.append('notifyMail', notifyMail);
      formData.append('token', localStorage.getItem("token"));
      setLoading(true);
      axios.post(global.config.api_server+'credit',formData).then(res => {
        if(res.status === 200 && res.data.status === "success"){
          alertService.success('Credit Note Created');
          setTimeout(function(){
            window.location.reload();
          }, 1000);
        }else{
          alertService.error(res.data.error);
          setLoading(false);
        }
      }).catch(err => {
        alertService.error(err);
        setLoading(false);
      });
    }else{
      alertService.error('All the fields are required');
      setLoading(false);
    }
  }

  const handleArchive = (event) =>{
    if(event.target.checked){
      setArchive(1);
    }else{
      setArchive(0);
    }
  }

  const handleNotifySms = (event) =>{
    if(event.target.checked){
      setNotifySms(1);
    }else{
      setNotifySms(0);
    }
  }

  const handleNotifyMail = (event) =>{
    if(event.target.checked){
      setNotifyMail(1);
    }else{
      setNotifyMail(0);
    }
  }
  
  const handleSearch = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    let currentSearch = search;
    if(name == "search"){
      setSearch(value);
      currentSearch = value;
    }
    searchData[name] = value;
    const filtered = [];
    for(let i = 0;i<credits.length;i++){
      const filterDate = new Date(credits[i].updation_date);
      let datePassed = true;
      if(dateSearch === 'range' && (filterDate < new Date(searchData.dateSearchFrom) || filterDate > new Date(searchData.dateSearchTo))){
        datePassed = false;
      }
      if(dateSearch === "monthYear"){
        if(searchData.dateSearchMonth > 0 && searchData.dateSearchYear > 0 && filterDate.getMonth()+1 != searchData.dateSearchMonth && filterDate.getFullYear() != searchData.dateSearchYear){
          datePassed = false;
        }else if(searchData.dateSearchMonth > 0 && filterDate.getMonth()+1 != searchData.dateSearchMonth){
          datePassed = false;
        }else if(searchData.dateSearchYear > 0 && filterDate.getFullYear() != searchData.dateSearchYear){
          datePassed = false;
        }
      }
      if(datePassed && (currentSearch === undefined || currentSearch.trim() === '' || credits[i].creadit_id.indexOf(currentSearch) >= 0 || credits[i].description.indexOf(currentSearch) >= 0 || credits[i].updation_date.indexOf(currentSearch) >= 0 || credits[i].amount.indexOf(currentSearch) >= 0 || credits[i].status.indexOf(currentSearch) >= 0)){
        filtered.push(credits[i]);
      }
    }
    if(sortBy === 'credit_id' || sortBy === 'amount'){
      setFilteredCredits(sortObj(filtered, sortBy, sortOrder, 'number'));
    }else{
      setFilteredCredits(sortObj(filtered, sortBy, sortOrder));
    }
  }

  const updateSortParam = (e, field) => {
    let newSearchOrder = true;
    if(field === sortBy){
      newSearchOrder = !sortOrder;
    }else{
      setSortBy(field);
    }
    const sortList = filteredCredits;
    setSortOrder(newSearchOrder);
    if(field === 'credit_id' || field === 'amount'){
      setFilteredCredits(sortObj(sortList, field, newSearchOrder, 'number'));
    }else{
      setFilteredCredits(sortObj(sortList, field, newSearchOrder));
    }
  }
  
  const resetSearch = (event) => {
    searchForm.current.reset();
    setSearch('');
    if(sortBy === 'credit_id' || sortBy === 'amount'){
      setFilteredCredits(sortObj(credits, sortBy, sortOrder, 'number'));
    }else{
      setFilteredCredits(sortObj(credits, sortBy, sortOrder));
    }
    setDateSearch("none");
    searchData = {};
  }
  
  useEffect(()=>{
    let uri = global.config.api_server+'credits?archive='+archive+'&token='+localStorage.getItem("token");
    axios.get(uri)
    .then(res => {
      if(res.status === 200 && res.data.status === "success"){
        const temp = res.data.credits;
        setCredits(temp)
        setFilteredCredits(sortObj(temp, sortBy, sortOrder, 'number'));
        setLoading(false);
      }
    }).catch(err => {
      alertService.error(err);
    });
  }, [archive]);
  
  const onPageChange = (index_start) => {
    setOffset(index_start);
  }

  let total = 0;
  let counter = 0;
  let offsetCounter = 0;
  const paginated_credits = [];
  for (const u of filteredCredits) {
    total++;
    offsetCounter++;
    if (offsetCounter > offset && counter < global.config.items_per_page){
      counter++;
      paginated_credits.push(u);
    }
  }

  const date = new Date(validTill);
  let validTill_date = date.getFullYear() + "-";
  if((date.getMonth() + 1) < 10){
    validTill_date += "0";
  }
  validTill_date += (date.getMonth() + 1);
  validTill_date += "-";
  if(date.getDate() < 10){
    validTill_date += "0";
  }
  validTill_date += date.getDate();
  if(loading){
    return <Progress/>;
  }else{
    return(
      <div className="container-fluid">
        <div className="card shadow mb-4">
          <div className="card-header py-3">
            <a href="#" className={'btn btn-icon-split float-right btn-'+theme()} data-toggle="modal" data-target="#createNewCreditModal">
              <span className="icon text-white-50">
                <i className="fas fa-credit-card"></i>
              </span>
              <span className="text">Create Credit Note</span>
            </a>
            <div class="form-group float-right mr-2 mt-2"><input type="checkbox" onChange={(e) => handleArchive(e)}/> Include Archived</div>
            <form ref={searchForm} className="form-inline">
              <h6 className={'form-group mr-3 font-weight-bold text-'+theme()}>Credits</h6>
              <div className="form-group mr-3">
                <input type="search" name="search" className="form-control" placeholder="Search" onChange={handleSearch}/>
              </div>
              <div className={"form-group mr-3"}>
                <select name="dateSearch" className="form-control" value={dateSearch} id="dateSearch" onChange={(e) => setDateSearch(e.target.value)}>
                  <option value="none">Search By Date</option>
                  <option value="range">Range</option>
                  <option value="monthYear">Month-Year</option>
                </select>
              </div>
              {dateSearch === 'range' && 
                <div className="form-group mr-3">
                  <input type="date" name="dateSearchFrom" className="form-control" placeholder="From" onChange={handleSearch}/>
                </div>
              }
              {dateSearch === 'range' && 
                <div className="form-group mr-3">
                  <input type="date" name="dateSearchTo" className="form-control" placeholder="To" onChange={handleSearch}/>
                </div>
              }
              {dateSearch === 'monthYear' && 
                <div className="form-group mr-3">
                  <select name="dateSearchMonth" className="form-control" id="dateSearchMonth" onChange={handleSearch}>
                    <option value="0">None</option>
                    <option value="1">January</option>
                    <option value="2">February</option>
                    <option value="3">March</option>
                    <option value="4">April</option>
                    <option value="5">May</option>
                    <option value="6">June</option>
                    <option value="7">July</option>
                    <option value="8">August</option>
                    <option value="9">September</option>
                    <option value="10">October</option>
                    <option value="11">November</option>
                    <option value="12">December</option>
                  </select>
                </div>
              }
              {dateSearch === 'monthYear' && 
                <div className="form-group mr-3">
                  <select name="dateSearchYear" className="form-control" id="dateSearchYear" onChange={handleSearch}>
                    <option value="0">None</option>
                    <option value="2022">2022</option>
                    <option value="2023">2023</option>
                    <option value="2024">2024</option>
                    <option value="2025">2025</option>
                    <option value="2026">2026</option>
                    <option value="2027">2027</option>
                    <option value="2028">2028</option>
                    <option value="2029">2029</option>
                    <option value="2030">2030</option>
                    <option value="2031">2031</option>
                    <option value="2032">2032</option>
                    <option value="2033">2033</option>
                  </select>
                </div>
              }
              {(search || dateSearch != 'none' ) &&
                <div className="form-group mr-3">
                  <a href="#" className="btn btn-default btn-icon-split" onClick={resetSearch}>
                    <span className="icon">
                      <i className="fas fa-times"></i>
                    </span>
                  </a>
                </div>
              }
            </form>
          </div>
          <div className="card-body">
            <div className="table-responsive">
              <div className="row">
                <div className="col-sm-12">
                  <table className="table table-bordered" width="100%">
                    <thead>
                      <tr>
                        <th onClick={(e) => updateSortParam(e,'credit_id')}>Credit Id <Sort field="credit_id" sortBy={sortBy} sortOrder={sortOrder}/></th>
                        <th>Description</th>
                        <th onClick={(e) => updateSortParam(e,'status')}>Status <Sort field="status" sortBy={sortBy} sortOrder={sortOrder}/></th>
                        <th onClick={(e) => updateSortParam(e,'amount')}>Amount <Sort field="amount" sortBy={sortBy} sortOrder={sortOrder}/></th>
                        <th onClick={(e) => updateSortParam(e,'updation_date')}>Updated On <Sort field="updation_date" sortBy={sortBy} sortOrder={sortOrder}/></th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {paginated_credits.map(credit=>
                        <CreditListItem key={credit.credit_id} info={credit} type="all"/>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
              <Paginator
                itemCount={total}
                itemsPerPage={global.config.items_per_page}
                showNextPages={2}
                showPrevPages={2}
                filterTotal={filteredCredits.length}
                grandTotal={credits.length}
                onClick={onPageChange.bind(this)}
              />
            </div>
          </div>
        </div>
        <div className="modal fade" id="createNewCreditModal" tabIndex="-1" role="dialog" aria-labelledby="create-popup" aria-hidden="true">
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="create-popup">Create Credit</h5>
                <button className="close" type="button" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <form>
                <div className="modal-body">
                  <SearchClient handleSubmit={(e) => setClient(e)}></SearchClient>
                  <div className="form-group">
                    <label>Amount</label>
                    <input type="number" value={amount} maxLength="50" name="amount" className="form-control" id="amount" placeholder="Enter Amount" onChange={(e) => setAmount(e.target.value)}/>
                  </div>
                  <div className="form-group">
                    <label>Description</label>
                    <textarea maxLength="400" name="description" className="form-control textarea-lg" id="description" placeholder="Description" onChange={(e) => setDescription(e.target.value)}></textarea>
                  </div>
                  <div className="form-group">
                    <label>Valid Till</label>
                    <input type="date" value={validTill_date} maxLength="50" name="validTill" className="form-control" id="validTill" placeholder="Enter Valid Till" onChange={(e) => setValidTill(e.target.value)}/>
                  </div>
                  <div className="form-group">
                    <label>Additional Note</label>
                    <textarea maxLength="200" value={remark} name="remark" className="form-control textarea-md" id="remark" placeholder="Additional Note" onChange={(e) => setRemark(e.target.value)}></textarea>
                  </div>
                  <div className="form-group">
                    <input type="checkbox" onChange={(e) => handleNotifyMail(e)}/> Send Mail &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<input type="checkbox" onChange={(e) => handleNotifySms(e)}/> Send SMS
                  </div>
                  {loading === true ? (
                    <Progress label='Creating..' small={true}/>
                  ):null}
                </div>
                <div className="modal-footer">
                  <button className="btn btn-default" type="reset" data-dismiss="modal">Close</button>
                  <button className={'btn btn-'+theme()} type="button" onClick={handleSubmit}>Submit</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Credits;