import React, {useEffect} from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import axios from 'axios';
import Dashboard from './dashboard.js';
import Profile from './profile.js';
import Users from './users.js';
import Clients from './clients.js';
import Client from './client.js';
import User from './user.js';
import Settings from './settings.js';
import Invoices from './invoices.js';
import Invoice from './invoice.js';
import Receipts from './receipts.js';
import Receipt from './receipt.js';
import Credits from './credits.js';
import Credit from './credit.js';
import Topnav from '../components/topnav.js';
import Footer from '../components/footer.js';
import Festivals from './festivals.js';
import Festival from './festival.js';
import Templates from './templates.js';
import Template from './template.js';
import Export from './export.js';
import Report from './report.js';
import Public from './public.js';

import { alertService } from '../services/alert.service.js';
import {Alert} from '../components/alert.js';

import Navigation from '../components/navigation.js';


function Base(props) {
  
  useEffect(()=>{
    if(window.location.pathname.indexOf("public") <= -1){
      const currentTheme = localStorage.getItem('theme');
      axios.get(global.config.api_server+'login?token='+localStorage.getItem("token"))
      .then(res => {
        let status = false;
        if(res.status == 200 && res.data.status === "success" && res.data.user){
          localStorage.setItem('name', res.data.user.name);
          localStorage.setItem('email', res.data.user.email);
          localStorage.setItem('mobile', res.data.user.mobile);
          localStorage.setItem('role', res.data.user.role);
          localStorage.setItem('user_id', res.data.user.user_id);
          localStorage.setItem('supervisor', res.data.user.supervisor);
          localStorage.setItem('theme', res.data.user.theme);
          status = true;
        }else if(res.data.error !== undefined){
          alertService.error(res.data.error);
        }else{
          alertService.error("Unknown Error");
        }
        if(!status){
          localStorage.clear();
          localStorage.setItem('theme', currentTheme);
          setTimeout(
            () => window.location.reload(), 
            2000
          );
        }
      }).catch(error => {
        alertService.error("Network error");
        localStorage.clear();
        localStorage.setItem('theme', currentTheme);
        window.location.reload();
      });
    }
  }, []);
  
  return(
      <BrowserRouter>
        <Navigation/>
        <Alert />
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <Topnav/>
              <Routes>
                <Route path="*" element={<Dashboard />} />
                <Route index path='/' element={ <Dashboard /> } ></Route>
                <Route exact path='/profile' element={ <Profile /> } ></Route>
                <Route exact path='/profile/:subnav' element={ <Profile /> } ></Route>
                <Route exact path='/profile' element={ <Profile /> } ></Route>
                <Route exact path='/profile' element={ <Profile /> } ></Route>
                <Route exact path='/users' element={ <Users /> } ></Route>
                <Route exact path='/user/:id' element={ <User /> } ></Route>
                <Route exact path='/user/:id/:subnav' element={ <User /> } ></Route>
                <Route exact path='/clients' element={ <Clients /> } ></Route>
                <Route exact path='/client/:id' element={ <Client /> } ></Route>
                <Route exact path='/client/:id/:subnav' element={ <Client /> } ></Route>
                <Route exact path='/invoices' element={ <Invoices /> } ></Route>
                <Route exact path='/invoice/:id/:subnav' element={ <Invoice /> } ></Route>
                <Route exact path='/receipts' element={ <Receipts /> } ></Route>
                <Route exact path='/receipt/:id/:subnav' element={ <Receipt /> } ></Route>
                <Route exact path='/credits' element={ <Credits /> } ></Route>
                <Route exact path='/credit/:id/:subnav' element={ <Credit /> } ></Route>
                <Route exact path='/festivals' element={ <Festivals /> } ></Route>
                <Route exact path='/festival/:id' element={ <Festival /> } ></Route>
                <Route exact path='/template/:type' element={ <Templates /> } ></Route>
                <Route exact path='/template/:type/:id' element={ <Template /> } ></Route>
                <Route exact path='/festival/:id' element={ <Festival /> } ></Route>
                <Route exact path='/settings' element={ <Settings /> } ></Route>
                <Route exact path='/export' element={ <Export /> } ></Route>
                <Route exact path='/report/:subnav' element={ <Report /> } ></Route>
                <Route exact path='/public/:subnav/:id' element={ <Public /> } ></Route>
              </Routes>
          </div>
          <Footer/>
        </div>
      </BrowserRouter>
  );
}

export default Base;