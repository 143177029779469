import React, {useState, useEffect, useRef} from 'react';
import { useParams } from 'react-router';
import {Link, useNavigate} from 'react-router-dom';
import axios from 'axios';
import '../config.js';

import { theme } from '../services/helper.js';
import { alertService } from '../services/alert.service.js';
import Progress from '../components/progress.js';

function Templates() {
  const { type } = useParams();
  const [templates, setTemplates] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  if(type != 'sms' && type != 'mail'){
    navigate("/");
  }
  
  useEffect(()=>{
    let uri = global.config.api_server+'template?type='+type+'&token='+localStorage.getItem("token");
    axios.get(uri)
    .then(res => {
      if(res.status === 200 && res.data.status === "success"){
        const temp = res.data.templates;
        setTemplates(temp);
        setLoading(false);
      }
    }).catch(err => {
      alertService.error(err);
    });
  }, [type]);
  
  if(loading){
    return <Progress/>;
  }else{
    return(
      <div className="container-fluid">
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
        {type == 'mail' &&
            <h1 className="h3 mb-0 text-gray-800">Mail Templates</h1>
        }
        {type == 'sms' &&
            <h1 className="h3 mb-0 text-gray-800">SMS Templates</h1>
        }
        </div>
        <div className="row">
          {templates.map(t=>
            <div key={t.token} className="col-xl-4 col-md-6 mb-4">
              <div className={"card shadow h-100 py-2 border-left-"+theme()}>
                <div className="card-header py-3">
                  <Link to={"/template/"+type+"/"+t.token}>
                    <h6 className={'mt-2 font-weight-bold text-'+theme()}>
                      {t.status == 'Active' && <i className="fas fa-fw fa-check"></i> }
                      {t.subject} <small>({t.token})</small>
                    </h6>
                  </Link>
                </div>
                <div className="card-body">
                    <div className="row no-gutters align-items-center">
                        <div className="col mr-2">
                            <div className="h5 mb-0 text-gray-800"dangerouslySetInnerHTML={{ __html: t.description }}></div>
                        </div>
                    </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default Templates;