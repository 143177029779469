import React, {useState, useEffect, useRef} from 'react';
import axios from 'axios';
import '../config.js';
import RichTextEditor from '../components/rte.js';
import { theme, createFormData } from '../services/helper.js';
import { alertService } from '../services/alert.service.js';
import Progress from '../components/progress.js';

function TemplateBasicInfo(props){
  const [loading, setLoading] = useState(false);
  const [description, setDescription] = useState(props.template.description);
  const [subject, setSubject] = useState(props.template.subject);
  const [status, setStatus] = useState(props.template.status);
  const [file, setFile] = useState(null);
  const [otp, setOtp] = useState(null);
  const userRole = localStorage.getItem("role");
  
  const handleSubmit = (event) => {
    event.preventDefault();
    if(subject && description && status && (userRole == 10 || otp)){
      setLoading(true);
      const formData = new FormData;
      if(file != null && file != undefined){
        formData.append('file', file);
        formData.append('file_name', file.name);
      }
      formData.append('description', description);
      formData.append('status', status);
      formData.append('subject', subject);
      formData.append('task', "edit");
      formData.append('id', props.id);
      formData.append('otp', otp);
      formData.append('token', localStorage.getItem("token"));
      setLoading(true);
      axios.post(global.config.api_server+'template',formData).then(res => {
        if(res.status === 200 && res.data.status === "success"){
          alertService.success('Template updated');
          setTimeout(function(){
            window.location.reload();
          }, 1000);
        }else{
          alertService.error(res.data.error);
          setLoading(false);
        }
      }).catch(err => {
        alertService.error(err);
        setLoading(false);
      });
    }else{
      alertService.error('Some fields are required');
    }
  }

  const reqPermissionOtp = (event) => {
    if(userRole < 10){
      let payloadType = "MAIL_UPDATE";
      if(props.type == "sms"){
        payloadType = "SMS_UPDATE";
      }
      axios.get(global.config.api_server+'permission?payloadType='+payloadType+'&payload='+props.id+'&description='+props.id+'&token='+localStorage.getItem("token")).then(res => {
        if(res.status === 200 && res.data.status === "success"){
          alertService.success('OTP Requested');
        }else{
          alertService.error(res.data.error);
        }
      }).catch(err => {
        alertService.error(err);
      });
    }
  }

  let showcase = "No attachment found";
  if(props.template.uri){
    showcase = <a href={global.config.asset_server + props.template.uri} className={'btn btn-icon-split btn-'+theme()}>
                  <span className="icon text-white-50">
                    <i className="fas fa-download"></i>
                  </span>
                  <span className="text">Download</span>
                </a>
    if(props.template.uri.toUpperCase().endsWith('.JPG') || props.template.uri.toUpperCase().endsWith('.JPEG') || props.template.uri.toUpperCase().endsWith('.PNG') || props.template.uri.toUpperCase().endsWith('.GIF')){
      showcase = <a href={props.template.uri}>
                    <img src={global.config.asset_server + props.template.uri} width="100%" height="auto"/>
                  </a>
    }
  }

  const statuses = global.config.status.split(",");
  const variableList = global.config.variable_list[props.template.token];
  return(
    <div className="card shadow mb-4">
      <div className="card-header py-3">
        {userRole == 10 &&        
          <button onClick={(e) => handleSubmit(e)} className={'btn btn-icon-split float-right btn-'+theme()}>
            <span className="icon text-white-50">
              <i className="fas fa-save"></i>
            </span>
            <span className="text">Save</span>
          </button>
        }
        {userRole != 10 && 
          <button className={'btn btn-icon-split float-right btn-'+theme()} data-toggle="modal" data-target="#updateTemplateModal" onClick={reqPermissionOtp}>
            <span className="icon text-white-50">
              <i className="fas fa-save"></i>
            </span>
            <span className="text">Save</span>
          </button>
        }
        <h6 className={'mt-2 font-weight-bold text-'+theme()}>Template Detail <small>({props.template.token})</small></h6>
      </div>
      <div className="card-body">
        <div className="row">
          {loading === true ? (
            <Progress label='Saving' small={true}/>
          ):null}
          <div className="col-md-6">
            {props.type == 'mail' ?
              <div className="form-group">
                <label>Subject *</label>
                <input type="text" value={subject} maxLength="100" name="subject" className="form-control" id="subject" placeholder="Enter Subject" onChange={(e) => setSubject(e.target.value)}/>
              </div>
              :
              <div className="form-group">
                <label>Template ID *</label>
                <input type="text" value={subject} maxLength="100" name="subject" className="form-control" id="subject" placeholder="Enter Template ID" onChange={(e) => setSubject(e.target.value)}/>
              </div>
            }
            <div className="form-group">
              <label>Status</label>
              <select name="status" className="form-control" value={status} id="status" onChange={(e) => setStatus(e.target.value)}>
                {statuses.map(d =>
                    <option value={d} key={d}>{d}</option>
                )}
              </select>
            </div>
            {props.type == "mail" &&
              <div>
                <div className="form-group">
                  <label>Attachment</label>
                  <input type="file" maxLength="50" name="file" className="form-control" id="file" placeholder="Upload File" onChange={(e) => setFile(e.target.files[0])}/>
                </div>
                {showcase}
              </div>
            }
          </div>
          <div className="col-md-6">
            {props.type == "mail" &&
              <div className="form-group">
                <label>Mail Body - <small>variable to be used : {variableList}</small></label>
                <RichTextEditor id={'template-description-'+props.template.template_id} label={'Mail Body'} value={description} handleSubmit={(e)=>setDescription(e)}/>
              </div>
            }
            {props.type == "sms" &&
              <div className="form-group">
                <label>SMS Text  - <small>variable to be used : {variableList}</small></label>
                <textarea type="text" value={description} maxLength="2000" name="description" className="form-control textarea-xxlg" id="description" placeholder="Enter SMS Text" onChange={(e) => setDescription(e.target.value)}></textarea>
              </div>
            }
          </div>
        </div>
      </div>
      {userRole != 10 && 
        <div className="modal fade" id="updateTemplateModal" tabIndex="-1" role="dialog" aria-labelledby="update-template-popup" aria-hidden="true">
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="update-template-popup">Updating Template</h5>
                <button className="close" type="button" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="form-group">
                  <label>Enter OTP to continue.</label>
                  <input type="text" maxLength="50" name="otp" value={otp} className="form-control" id="otp" placeholder="Enter OTP" onChange={(e) => setOtp(e.target.value)}/>
                </div>
              </div>
              <div className="modal-footer">
                <button className="btn btn-default" type="button" data-dismiss="modal">Cancel</button>
                <button className={'btn ml-3 btn-'+theme()} onClick={(e) => handleSubmit(e)}  data-dismiss="modal">Save</button>
              </div>
            </div>
          </div>
        </div>
        }
    </div>
  )
}

export default TemplateBasicInfo;