import React from 'react';
import {Link} from 'react-router-dom';
import '../config.js';

import { theme } from '../services/helper.js';

function ClientListItem(props) {
  
  return(
        <tr>
          <td>{props.info.salutation} {props.info.first_name} {props.info.middle_name} {props.info.last_name}</td>
          <td>{props.info.email}</td>
          <td>{props.info.mobile}</td>
          <td>{props.info.type}</td>
          <td>
            <Link to={"/client/"+props.info.client_id} className={'mr-2 btn btn-icon-split btn-'+theme()} data-toggle="tooltip" data-placement="bottom" title="Info">
              <span className="icon text-white-50">
                <i className="fas fa-edit"></i>
              </span>
            </Link>
            <Link to={"/client/"+props.info.client_id+"/document"} className={'mr-2 btn btn-icon-split btn-'+theme()} data-toggle="tooltip" data-placement="bottom" title="Documents">
              <span className="icon text-white-50">
                <i className="fas fa-archive"></i>
              </span>
            </Link>
            <Link to={"/client/"+props.info.client_id+"/invoice"} className={'mr-2 btn btn-icon-split btn-'+theme()} data-toggle="tooltip" data-placement="bottom" title="Proforma Invoices">
              <span className="icon text-white-50">
                <i className="fas fa-clipboard"></i>
              </span>
            </Link>
            <Link to={"/client/"+props.info.client_id+"/receipt"} className={'mr-2 btn btn-icon-split btn-'+theme()} data-toggle="tooltip" data-placement="bottom" title="Receipts">
              <span className="icon text-white-50">
                <i className="fas fa-file"></i>
              </span>
            </Link>
            <Link to={"/client/"+props.info.client_id+"/credit"} className={'mr-2 btn btn-icon-split btn-'+theme()} data-toggle="tooltip" data-placement="bottom" title="Credit Notes">
              <span className="icon text-white-50">
                <i className="fas fa-credit-card"></i>
              </span>
            </Link>
          </td>
        </tr>
  )
}

export default ClientListItem;