import React, {useState, useEffect, useRef} from 'react';
import { useParams } from 'react-router';
import { theme, themeColor } from '../services/helper.js';
import axios from 'axios';
import { alertService } from '../services/alert.service.js';
import Progress from '../components/progress.js';
import { Chart } from "react-google-charts";

function Report() {
    const [data, setData] = useState(null);
    const { subnav } = useParams();
    const [loading, setLoading] = useState(true);

    useEffect(()=>{
        let uri = global.config.api_server+'reports?type='+subnav+'&token='+localStorage.getItem("token");
        axios.get(uri)
        .then(res => {
            if(res.status === 200 && res.data.status === "success"){
                const temp = res.data.report;
                temp[0][2] = { role: 'style' };
                for(let i =1;i<temp.length;i++){
                    temp[i][2] = themeColor();
                }
                setData(temp)
                setLoading(false);
            }
        }).catch(err => {
            alertService.error(err);
            setLoading(false);
        });
    }, []);

    let heading = subnav;
    if(subnav == "client"){
        heading = "Clients"
    }else if(subnav == "invoice"){
        heading = "Proforma Invoices"
    }else if(subnav == "receipt"){
        heading = "Receipts"
    }else if(subnav == "invoice_amount"){
        heading = "Proforma Invoices (Amount)"
    }else if(subnav == "receipt_amount"){
        heading = "Receipts (Amount)"
    }else if(subnav == "credit_amount"){
        heading = "Credit Notes (Amount)"
    }

    if(loading){
        return <Progress/>;
    }else if(data != null){
        return(
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className={"card shadow h-100 py-2 border-left-"+theme()}>
                            <div className="card-header py-3">
                                <h6 className="m-0 font-weight-bold text-primary">Report - {heading}</h6>
                            </div>
                            <div className="card-body">
                                <div className="row no-gutters align-items-center">
                                    <Chart
                                        chartType="ColumnChart"
                                        width="100%"
                                        height="500px"
                                        data={data}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        )
    }
}

export default Report;