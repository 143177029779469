import React, {useState, useEffect, useRef} from 'react';
import { useParams } from 'react-router';
import { theme, themeColor } from '../services/helper.js';
import axios from 'axios';
import { alertService } from '../services/alert.service.js';
import Progress from '../components/progress.js';

function Public() {
    const [data, setData] = useState(null);
    const { subnav, id } = useParams();
    const [loading, setLoading] = useState(true);

    useEffect(()=>{
        let uri = global.config.api_server+'/'+subnav+'?id='+id+'&token='+localStorage.getItem("token");
        axios.get(uri).then(res => {
            if(res.status === 200 && res.data.status === "success"){
                if(subnav === 'invoice'){
                    setData(res.data.invoice);
                }else if(subnav === "receipt"){
                    setData(res.data.receipt);
                }else if(subnav === "credit"){
                    setData(res.data.credit);
                }
            }
            console.log(data);
            setLoading(false);
        }).catch(err => {
            alertService.error(err);
            setLoading(false);
        });
    }, []);

    
    let heading = subnav;
    if(subnav == "invoice"){
        heading = "Proforma Invoice"
    }else if(subnav == "receipt"){
        heading = "Receipt"
    }else if(subnav == "credit"){
        heading = "Credit Note"
    }

    if(loading){
        return <Progress/>;
    }else if(data != null){
        return(
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className={"card shadow h-100 py-2 border-left-"+theme()}>
                            <div className="card-header py-3">
                                <h6 className="m-0 font-weight-bold text-primary">{heading}</h6>
                            </div>
                            <div className="card-body">
                                <div className="row no-gutters align-items-center">
                                    <table className="table table-bordered" width="100%">
                                        <thead>
                                            <tr>
                                                <th>Item</th>
                                                <th>Value</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th>Name</th>
                                                <td>{data.client_info.salutation} {data.client_info.first_name} {data.client_info.middle_name} {data.client_info.last_name}</td>
                                            </tr>
                                            <tr>
                                                <th>Email</th>
                                                <td>{data.client_info.email}</td>
                                            </tr>
                                            <tr>
                                                <th>Mobile</th>
                                                <td>{data.client_info.mobile}</td>
                                            </tr>
                                            <tr>
                                                <th>Amount</th>
                                                <td>{data.amount}</td>
                                            </tr>
                                            <tr>
                                                <th>Created On</th>
                                                <td>{data.creation_date}</td>
                                            </tr>
                                            <tr>
                                                <th>Updated On</th>
                                                <td>{data.updation_date}</td>
                                            </tr>
                                            {subnav === 'credit' && 
                                                <tr>
                                                    <th>Status</th>
                                                    <td>{data.status}</td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                    </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        )
    }else{
        return(
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className={"card shadow h-100 py-2 border-left-"+theme()}>
                            <div className="card-header py-3">
                                <h6 className="m-0 font-weight-bold text-primary">{heading}</h6>
                            </div>
                            <div className="card-body">
                                <div className="row no-gutters align-items-center">
                                    <strong>NO RECORD FOUND</strong>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Public;