import React, {useState, useRef} from 'react';
import axios from 'axios';
import '../config.js';

import { theme } from '../services/helper.js';
import { alertService } from '../services/alert.service.js';
import Progress from './progress.js';
import InvoiceListItem from './invoiceListItem.js';
import { Paginator } from './paginator.js';

function ClientInvoiceInfo(props){
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [filteredInvoices, setFilteredInvoices] = useState(props.client.invoices);
  const [offset, setOffset] = useState(0);
  const [invoices, setInvoices] = useState(props.client.invoices);
  const [description, setDescription] = useState(null);
  const [rate, setRate] = useState(0);
  const [pax, setPax] = useState(1);
  const [tax, setTax] = useState(0);
  const [notifySms, setNotifySms] = useState(0);
  const [notifyMail, setNotifyMail] = useState(0);

  let searchForm = useRef();

  const handleSearch = (event) => {
    const target = event.target;
    const value = target.value;
    const filtered = [];
    for(let i = 0;i<invoices.length;i++){
      if(value === undefined || value.trim() === '' || invoices[i].description.indexOf(value) >= 0 || invoices[i].updation_date.indexOf(value) >= 0 || invoices[i].amount.indexOf(value) >= 0){
        filtered.push(invoices[i]);
      }
    }
    setFilteredInvoices(filtered);
  }

  const handleSubmit = (event) =>{
    event.preventDefault();
    if(rate && rate > 0 && pax && pax > 0 && description){
      const formData = new FormData;
      formData.append('description', description);
      formData.append('task', "create");
      formData.append('client', props.id);
      formData.append('rate', rate);
      formData.append('pax', pax);
      formData.append('tax', tax);
      formData.append('notifySms', notifySms);
      formData.append('notifyMail', notifyMail);
      formData.append('token', localStorage.getItem("token"));
      setLoading(true);
      axios.post(global.config.api_server+'invoice',formData).then(res => {
        if(res.status === 200 && res.data.status === "success"){
          alertService.success('Invoice Created');
          setTimeout(function(){
            window.location.reload();
          }, 1000);
        }else{
          alertService.error(res.data.error);
          setLoading(false);
        }
      }).catch(err => {
        alertService.error(err);
        setLoading(false);
      });
    }else{
      alertService.error('All the fields are required');
      setLoading(false);
    }
  }

  const handleNotifySms = (event) =>{
    if(event.target.checked){
      setNotifySms(1);
    }else{
      setNotifySms(0);
    }
  }

  const handleNotifyMail = (event) =>{
    if(event.target.checked){
      setNotifyMail(1);
    }else{
      setNotifyMail(0);
    }
  }

  const resetSearch = (event) => {
    searchForm.current.reset();
    setSearch('');
    setFilteredInvoices(invoices);
  }

  const onPageChange = (index_start) => {
    setOffset(index_start);
  }  

  let createInvoice = "";
  if(parseInt(localStorage.getItem("role")) >= 3){
    createInvoice = <a href="#" className={'btn btn-icon-split float-right btn-'+theme()} data-toggle="modal" data-target="#createInvoiceModal">
                  <span className="icon text-white-50">
                    <i className="fas fa-clipboard"></i>
                  </span>
                  <span className="text">Add New Invoice</span>
                </a>;
  }
  let total = 0;
  let counter = 0;
  let offsetCounter = 0;
  const paginated_invoices = [];
  for (const u of filteredInvoices) {
    total++;
    offsetCounter++;
    if (offsetCounter > offset && counter < global.config.items_per_page){
      counter++;
      paginated_invoices.push(u);
    }
  }
  return(
    <div>
      <div className="card shadow mb-4">
        <div className="card-header py-3">
          {createInvoice}
          <form ref={searchForm} className="form-inline">
              <h6 className={'form-group mr-3 font-weight-bold text-'+theme()}>Client - Proforma Invoices(s)</h6>
              <div className="form-group mr-3">
                <input type="search" label="search" className="form-control" placeholder="Search" onChange={handleSearch}/>
              </div>
              {search &&
              <div className="form-group mr-3">
                <a href="#" className="btn btn-default btn-icon-split" onClick={resetSearch}>
                  <span className="icon">
                    <i className="fas fa-times"></i>
                  </span>
                </a>
              </div>
              }
            </form>
        </div>
        <div className="card-body">
          <div className="table-responsive">
            <div className="row">
            {loading === true ? (
              <Progress label='Loading..' small={true}/>
            ):null}
              <div className="col-sm-12">
                <table className="table table-bordered" width="100%">
                  <thead>
                    <tr>
                      <th>Proforma Invoice Id</th>
                      <th>Description</th>
                      <th>Pax</th>
                      <th>Total Amount</th>
                      <th>Paid</th>
                      <th>Balance</th>
                      <th>Updated On</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {paginated_invoices.map(invoice =>
                      <InvoiceListItem info={invoice} key={invoice.invoice_id} type="client"/>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            <Paginator
                itemCount={total}
                itemsPerPage={global.config.items_per_page}
                showNextPages={2}
                showPrevPages={2}
                filterTotal={filteredInvoices.length}
                grandTotal={invoices.length}
                onClick={onPageChange.bind(this)}
              />
          </div>
        </div>
      </div>
      <div className="modal fade" id="createInvoiceModal" tabIndex="-1" role="dialog" aria-labelledby="create-popup" aria-hidden="true">
        <div className="modal-dialog modal-xl" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="create-popup">Create Invoice</h5>
              <button className="close" type="button" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <form>
              <div className="modal-body">
                <div className="form-group">
                  <label>Number of Pax</label>
                  <input type="number" value={pax} maxLength="50" name="pax" className="form-control" id="pax" placeholder="Enter Pax" onChange={(e) => setPax(e.target.value)}/>
                </div>
                <div className="form-group">
                  <label>Rate per pax</label>
                  <input type="number" value={rate} maxLength="50" name="rate" className="form-control" id="rate" placeholder="Enter Rate per pax" onChange={(e) => setRate(e.target.value)}/>
                </div>
                <div className="form-group">
                  <label>Services</label>
                  <textarea maxLength="400" name="description" className="form-control textarea-lg" id="description" placeholder="Services" onChange={(e) => setDescription(e.target.value)}></textarea>
                </div>
                <div className="form-group">
                  <input type="checkbox" onChange={(e) => handleNotifyMail(e)}/> Send Mail &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<input type="checkbox" onChange={(e) => handleNotifySms(e)}/> Send SMS
                </div>
                {loading === true ? (
                  <Progress label='Creating..' small={true}/>
                ):null}
              </div>
              <div className="modal-footer">
                <button className="btn btn-default" type="reset" data-dismiss="modal">Close</button>
                <button className={'btn btn-'+theme()} type="button" onClick={handleSubmit}>Submit</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ClientInvoiceInfo;