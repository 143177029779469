import React, {useState, useEffect, useRef} from 'react';
import axios from 'axios';
import '../config.js';

import { theme, sortObj } from '../services/helper.js';
import { alertService } from '../services/alert.service.js';
import ReceiptListItem from '../components/receiptListItem.js';
import Progress from '../components/progress.js';
import { Paginator } from '../components/paginator.js';
import SearchClient from '../components/searchClient.js';
import SearchInvoice from '../components/searchInvoice.js';
import Sort from '../components/sort.js';
let searchData = {};

function Receipts() {
  const [search, setSearch] = useState('');
  const [sortBy, setSortBy] = useState('receipt_id');
  const [sortOrder, setSortOrder] = useState(true);
  const [dateSearch, setDateSearch] = useState('none');
  const [filteredReceipts, setFilteredReceipts] = useState([]);
  const [receipts, setReceipts] = useState([]);
  const [offset, setOffset] = useState(0);
  const [loading, setLoading] = useState(true);
  const [description, setDescription] = useState(null);
  const [amount, setAmount] = useState(0);
  const [mode, setMode] = useState('Cash');
  const [notifySms, setNotifySms] = useState(0);
  const [notifyMail, setNotifyMail] = useState(0);
  const [client, setClient] = useState(null);
  const [invoice, setInvoice] = useState(null);
  const [archive, setArchive] = useState(0);
  
  let searchForm = useRef();

  const handleSubmit = (event) =>{
    event.preventDefault();
    if(amount && amount > 0 && description && client && invoice){
      const formData = new FormData;
      formData.append('description', description);
      formData.append('task', "create");
      formData.append('client', client.client_id);
      formData.append('invoice', invoice.invoice_id);
      formData.append('mode', mode);
      formData.append('amount', amount);
      formData.append('notifySms', notifySms);
      formData.append('notifyMail', notifyMail);
      formData.append('token', localStorage.getItem("token"));
      setLoading(true);
      axios.post(global.config.api_server+'receipt',formData).then(res => {
        if(res.status === 200 && res.data.status === "success"){
          alertService.success('Receipt Created');
          setTimeout(function(){
            window.location.reload();
          }, 1000);
        }else{
          alertService.error(res.data.error);
          setLoading(false);
        }
      }).catch(err => {
        alertService.error(err);
        setLoading(false);
      });
    }else{
      alertService.error('All the fields are required');
      setLoading(false);
    }
  }

  const handleArchive = (event) =>{
    if(event.target.checked){
      setArchive(1);
    }else{
      setArchive(0);
    }
  }

  const handleNotifySms = (event) =>{
    if(event.target.checked){
      setNotifySms(1);
    }else{
      setNotifySms(0);
    }
  }

  const handleNotifyMail = (event) =>{
    if(event.target.checked){
      setNotifyMail(1);
    }else{
      setNotifyMail(0);
    }
  }
  
  const handleSearch = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    let currentSearch = search;
    if(name == "search"){
      setSearch(value);
      currentSearch = value;
    }
    searchData[name] = value;
    const filtered = [];
    for(let i = 0;i<receipts.length;i++){
      const filterDate = new Date(receipts[i].updation_date);
      let datePassed = true;
      if(dateSearch === 'range' && (filterDate < new Date(searchData.dateSearchFrom) || filterDate > new Date(searchData.dateSearchTo))){
        datePassed = false;
      }
      if(dateSearch === "monthYear"){
        if(searchData.dateSearchMonth > 0 && searchData.dateSearchYear > 0 && filterDate.getMonth()+1 != searchData.dateSearchMonth && filterDate.getFullYear() != searchData.dateSearchYear){
          datePassed = false;
        }else if(searchData.dateSearchMonth > 0 && filterDate.getMonth()+1 != searchData.dateSearchMonth){
          datePassed = false;
        }else if(searchData.dateSearchYear > 0 && filterDate.getFullYear() != searchData.dateSearchYear){
          datePassed = false;
        }
      }
      if(datePassed && (currentSearch === undefined || currentSearch.trim() === '' || receipts[i].receipt_id.indexOf(currentSearch) >= 0 || receipts[i].description.indexOf(currentSearch) >= 0 || receipts[i].updation_date.indexOf(currentSearch) >= 0 || receipts[i].amount.indexOf(currentSearch) >= 0 || receipts[i].receipt_id.indexOf(currentSearch) >= 0)){
        filtered.push(receipts[i]);
      }
    }
    if(sortBy === 'receipt_id' || sortBy === 'amount'){
      setFilteredReceipts(sortObj(filtered, sortBy, sortOrder, 'number'));
    }else{
      setFilteredReceipts(sortObj(filtered, sortBy, sortOrder));
    }
  }

  const updateSortParam = (e, field) => {
    let newSearchOrder = true;
    if(field === sortBy){
      newSearchOrder = !sortOrder;
    }else{
      setSortBy(field);
    }
    const sortList = filteredReceipts;
    setSortOrder(newSearchOrder);
    if(field === 'receipt_id' || field === 'amount'){
      setFilteredReceipts(sortObj(sortList, field, newSearchOrder, 'number'));
    }else{
      setFilteredReceipts(sortObj(sortList, field, newSearchOrder));
    }
  }
  
  const resetSearch = (event) => {
    searchForm.current.reset();
    setSearch('');
    if(sortBy === 'receipt_id' || sortBy === 'amount'){
      setFilteredReceipts(sortObj(receipts, sortBy, sortOrder, 'number'));
    }else{
      setFilteredReceipts(sortObj(receipts, sortBy, sortOrder));
    }
    setDateSearch("none");
    searchData = {};
  }
  
  useEffect(()=>{
    let uri = global.config.api_server+'receipts?archive='+archive+'&token='+localStorage.getItem("token");
    axios.get(uri)
    .then(res => {
      if(res.status === 200 && res.data.status === "success"){
        const temp = res.data.receipts;
        setReceipts(temp)
        setFilteredReceipts(sortObj(temp, sortBy, sortOrder, 'number'));
        setLoading(false);
      }
    }).catch(err => {
      alertService.error(err);
    });
  }, [archive]);
  
  const onPageChange = (index_start) => {
    setOffset(index_start);
  }

  let total = 0;
  let counter = 0;
  let offsetCounter = 0;
  const paginated_receipts = [];
  for (const u of filteredReceipts) {
    total++;
    offsetCounter++;
    if (offsetCounter > offset && counter < global.config.items_per_page){
      counter++;
      paginated_receipts.push(u);
    }
  }

  let balance = 0;
  if(invoice && amount){
    balance = (parseFloat(invoice.amount) - (parseFloat(invoice.paid) + parseFloat(amount)));
  }else if (invoice){
    balance = (parseFloat(invoice.amount) - parseFloat(invoice.paid));
  }
  if(loading){
    return <Progress/>;
  }else{
    return(
      <div className="container-fluid">
        <div className="card shadow mb-4">
          <div className="card-header py-3">
            <a href="#" className={'btn btn-icon-split float-right btn-'+theme()} data-toggle="modal" data-target="#createNewReceiptModal">
              <span className="icon text-white-50">
                <i className="fas fa-file"></i>
              </span>
              <span className="text">Create Receipt</span>
            </a>
            <div class="form-group float-right mr-2 mt-2"><input type="checkbox" onChange={(e) => handleArchive(e)}/> Include Archived</div>
            <form ref={searchForm} className="form-inline">
              <h6 className={'form-group mr-3 font-weight-bold text-'+theme()}>Receipts</h6>
              <div className="form-group mr-3">
                <input type="search" name="search" className="form-control" placeholder="Search" onChange={handleSearch}/>
              </div>
              <div className={"form-group mr-3"}>
                <select name="dateSearch" className="form-control" value={dateSearch} id="dateSearch" onChange={(e) => setDateSearch(e.target.value)}>
                  <option value="none">Search By Date</option>
                  <option value="range">Range</option>
                  <option value="monthYear">Month-Year</option>
                </select>
              </div>
              {dateSearch === 'range' && 
                <div className="form-group mr-3">
                  <input type="date" name="dateSearchFrom" className="form-control" placeholder="From" onChange={handleSearch}/>
                </div>
              }
              {dateSearch === 'range' && 
                <div className="form-group mr-3">
                  <input type="date" name="dateSearchTo" className="form-control" placeholder="To" onChange={handleSearch}/>
                </div>
              }
              {dateSearch === 'monthYear' && 
                <div className="form-group mr-3">
                  <select name="dateSearchMonth" className="form-control" id="dateSearchMonth" onChange={handleSearch}>
                    <option value="0">None</option>
                    <option value="1">January</option>
                    <option value="2">February</option>
                    <option value="3">March</option>
                    <option value="4">April</option>
                    <option value="5">May</option>
                    <option value="6">June</option>
                    <option value="7">July</option>
                    <option value="8">August</option>
                    <option value="9">September</option>
                    <option value="10">October</option>
                    <option value="11">November</option>
                    <option value="12">December</option>
                  </select>
                </div>
              }
              {dateSearch === 'monthYear' && 
                <div className="form-group mr-3">
                  <select name="dateSearchYear" className="form-control" id="dateSearchYear" onChange={handleSearch}>
                    <option value="0">None</option>
                    <option value="2022">2022</option>
                    <option value="2023">2023</option>
                    <option value="2024">2024</option>
                    <option value="2025">2025</option>
                    <option value="2026">2026</option>
                    <option value="2027">2027</option>
                    <option value="2028">2028</option>
                    <option value="2029">2029</option>
                    <option value="2030">2030</option>
                    <option value="2031">2031</option>
                    <option value="2032">2032</option>
                    <option value="2033">2033</option>
                  </select>
                </div>
              }
              {(search || dateSearch != 'none' ) &&
                <div className="form-group mr-3">
                  <a href="#" className="btn btn-default btn-icon-split" onClick={resetSearch}>
                    <span className="icon">
                      <i className="fas fa-times"></i>
                    </span>
                  </a>
                </div>
              }
            </form>
          </div>
          <div className="card-body">
            <div className="table-responsive">
              <div className="row">
                <div className="col-sm-12">
                  <table className="table table-bordered" width="100%">
                    <thead>
                      <tr>
                        <th onClick={(e) => updateSortParam(e,'receipt_id')}>Receipt Id <Sort field="receipt_id" sortBy={sortBy} sortOrder={sortOrder}/></th> 
                        <th>Additional Note</th>
                        <th onClick={(e) => updateSortParam(e,'mode')}>Mode of Payment <Sort field="mode" sortBy={sortBy} sortOrder={sortOrder}/></th>
                        <th onClick={(e) => updateSortParam(e,'amount')}>Amount <Sort field="amount" sortBy={sortBy} sortOrder={sortOrder}/></th>
                        <th onClick={(e) => updateSortParam(e,'updation_date')}>Updated On <Sort field="updation_date" sortBy={sortBy} sortOrder={sortOrder}/></th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {paginated_receipts.map(receipt=>
                        <ReceiptListItem key={receipt.receipt_id} info={receipt} type="all"/>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
              <Paginator
                itemCount={total}
                itemsPerPage={global.config.items_per_page}
                showNextPages={2}
                showPrevPages={2}
                filterTotal={filteredReceipts.length}
                grandTotal={receipts.length}
                onClick={onPageChange.bind(this)}
              />
            </div>
          </div>
        </div>
        <div className="modal fade" id="createNewReceiptModal" tabIndex="-1" role="dialog" aria-labelledby="create-popup" aria-hidden="true">
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="create-popup">Create Receipt</h5>
                <button className="close" type="button" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <form>
                <div className="modal-body">
                  <SearchClient handleSubmit={(e) => {setClient(e); setInvoice(null);}}></SearchClient>
                  {client && 
                    <SearchInvoice handleSubmit={(e) => setInvoice(e)} client={client.client_id}></SearchInvoice>
                  }
                  {invoice && 
                    <div>
                      <div class="row">
                        <div class="col-md-6">
                          <div className="form-group">
                            <label>Amount (Balance: {balance})</label>
                            <input type="number" value={amount} maxLength="50" name="amount" className="form-control" id="amount" placeholder="Enter Amount" onChange={(e) => setAmount(e.target.value)}/>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div className="form-group">
                            <label>Mode of Payment</label>
                            <select name="mode" className="form-control" id="mode" value={mode} onChange={(e) => setMode(e.target.value)}>
                              <option value="Cash">Cash</option>
                              <option value="Cheque">Cheque</option>
                              <option value="Online">Online</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <label>Additional Note</label>
                        <textarea maxLength="400" name="description" className="form-control textarea" id="description" placeholder="Additional Note" onChange={(e) => setDescription(e.target.value)}></textarea>
                      </div>
                      <div className="form-group">
                        <input type="checkbox" onChange={(e) => handleNotifyMail(e)}/> Send Mail &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<input type="checkbox" onChange={(e) => handleNotifySms(e)}/> Send SMS
                      </div>
                    </div>
                  }
                  {loading === true ? (
                    <Progress label='Creating..' small={true}/>
                  ):null}
                </div>
                <div className="modal-footer">
                  <button className="btn btn-default" type="reset" data-dismiss="modal">Close</button>
                  <button className={'btn btn-'+theme()} type="button" onClick={handleSubmit}>Submit</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Receipts;