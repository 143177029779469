import React from 'react';

function Sort(props) {

  if(props.sortBy === props.field){
    if(!props.sortOrder){
      return(
        <i className="fas fa-sort-amount-down"></i>
      )
    }else{
      return(
        <i className="fas fa-sort-amount-down-alt"></i>
      )
    }
  }else{
    return(
      <i className="fas fa-sort"></i>
    )
  }
}

export default Sort;