import React, {useState, useEffect, useRef} from 'react';
import axios from 'axios';
import '../config.js';
import RichTextEditor from '../components/rte.js';
import { theme, createFormData } from '../services/helper.js';
import { alertService } from '../services/alert.service.js';
import Progress from '../components/progress.js';

function CreditBasicInfo(props){
  const [loading, setLoading] = useState(false);
  const [description, setDescription] = useState(props.credit.description);
  const [amount, setAmount] = useState(props.credit.amount);
  const [status, setStatus] = useState(props.credit.status);
  const [remark, setRemark] = useState(props.credit.remark);
  const [otp, setOtp] = useState(null);
  const [notifySms, setNotifySms] = useState(0);
  const [notifyMail, setNotifyMail] = useState(0);
  const userRole = localStorage.getItem("role");
  
  const handleSubmit = (event) => {
    event.preventDefault();
    if(amount && description && status && (userRole == 10 || otp)){
      setLoading(true);
      axios.post(global.config.api_server+'credit',createFormData({
        description: description,
        amount: amount,
        token: localStorage.getItem("token"),
        id: props.id,
        task: 'edit',
        otp: otp,
        remark: remark,
        notifySms: notifySms,
        notifyMail: notifyMail,
        status: status
      })).then(res => {
        setLoading(false);
        if(res.status === 200 && res.data.status === "success"){
          alertService.success('Credit updated');
        }else{
          alertService.error(res.data.error);
        }
      }).catch(err => {
        alertService.error(err);
        setLoading(false);
      });
    }else{
      alertService.error('Some fields are required');
    }
  }

  const handleNotifySms = (event) =>{
    if(event.target.checked){
      setNotifySms(1);
    }else{
      setNotifySms(0);
    }
  }

  const handleNotifyMail = (event) =>{
    if(event.target.checked){
      setNotifyMail(1);
    }else{
      setNotifyMail(0);
    }
  }

  const reqPermissionOtp = (event) => {
    if(userRole < 10){
      const payloadType = "CREDIT_UPDATE";
      axios.get(global.config.api_server+'permission?payloadType='+payloadType+'&payload='+props.id+'&description='+props.credit.credit_id+' of '+props.credit.client_info.email+'&token='+localStorage.getItem("token")).then(res => {
        if(res.status === 200 && res.data.status === "success"){
          alertService.success('OTP Requested');
        }else{
          alertService.error(res.data.error);
        }
      }).catch(err => {
        alertService.error(err);
      });
    }
  }

  const creditStatuses = global.config.creditStatus.split(",");

  return(
    <div className="card shadow mb-4">
      <div className="card-header py-3">
        {userRole == 10 &&        
          <button onClick={(e) => handleSubmit(e)} className={'btn btn-icon-split float-right btn-'+theme()}>
            <span className="icon text-white-50">
              <i className="fas fa-save"></i>
            </span>
            <span className="text">Save</span>
          </button>
        }
        {userRole != 10 && 
          <button className={'btn btn-icon-split float-right btn-'+theme()} data-toggle="modal" data-target="#updateCreditModal" onClick={reqPermissionOtp}>
            <span className="icon text-white-50">
              <i className="fas fa-save"></i>
            </span>
            <span className="text">Save</span>
          </button>
        }
        <h6 className={'mt-2 font-weight-bold text-'+theme()}>Credit Detail</h6>
      </div>
      <div className="card-body">
        <div className="row">
          {loading === true ? (
            <Progress label='Saving' small={true}/>
          ):null}
          <div className="col-md-6">
            <div className="form-group">
              <label>Credit note id</label>
              <input type="text" value={props.credit.credit_id} disabled={true} className="form-control"/>
            </div>
            <div className="form-group">
              <label>Amount *</label>
              <input type="number" value={amount} maxLength="15" name="amount" className="form-control" id="amount" placeholder="Enter Amount" onChange={(e) => setAmount(e.target.value)}/>
            </div>
            <div className="form-group">
              <label>Status *</label>
              <select name="Status" className="form-control" value={status} id="Status" onChange={(e) => setStatus(e.target.value)}>
                {creditStatuses.map(d =>
                    <option value={d} key={d}>{d}</option>
                )}
              </select>
            </div>
            <div className="form-group">
              <input type="checkbox" onChange={(e) => handleNotifyMail(e)}/> Send Mail &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<input type="checkbox" onChange={(e) => handleNotifySms(e)}/> Send SMS
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label>Description *</label>
              <textarea value={description} className="form-control textarea-xlg" onChange={(e)=>setDescription(e.target.value)}></textarea>
            </div>
            <div className="form-group">
              <label>Additional Note</label>
              <textarea value={remark} maxLength="200" name="remark" className="form-control textarea-md" id="remark" placeholder="Enter Amount" onChange={(e) => setRemark(e.target.value)}></textarea>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <hr/>
            <div className="form-group">
              <label><strong>Stats</strong></label>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label>Updated</label>
              <input type="text" disabled={true} value={props.credit.updation_date} className="form-control"/>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label>Created</label>
              <input type="text" disabled={true} value={props.credit.creation_date} className="form-control"/>
            </div>
          </div>
        </div>
      </div>
      {userRole != 10 && 
        <div className="modal fade" id="updateCreditModal" tabIndex="-1" role="dialog" aria-labelledby="update-credit-popup" aria-hidden="true">
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="update-credit-popup">Updating Credit Note</h5>
                <button className="close" type="button" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="form-group">
                  <label>Enter OTP to continue.</label>
                  <input type="text" maxLength="50" name="otp" value={otp} className="form-control" id="otp" placeholder="Enter OTP" onChange={(e) => setOtp(e.target.value)}/>
                </div>
              </div>
              <div className="modal-footer">
                <button className="btn btn-default" type="button" data-dismiss="modal">Cancel</button>
                <button className={'btn ml-3 btn-'+theme()} onClick={(e) => handleSubmit(e)}  data-dismiss="modal">Save</button>
              </div>
            </div>
          </div>
        </div>
        }
    </div>
  )
}

export default CreditBasicInfo;