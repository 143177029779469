import React, {useState, useEffect, useRef} from 'react';
import axios from 'axios';
import '../config.js';

import { theme, createFormData } from '../services/helper.js';
import { alertService } from '../services/alert.service.js';
import Progress from '../components/progress.js';

function UserAdditionalInfo(props){
  const [loading, setLoading] = useState(false);
  const [pan, setPan] = useState(props.user.pan);
  const [bank, setBank] = useState(props.user.bank);
  const [bankBranch, setBankBranch] = useState(props.user.bank_branch);
  const [bankAccount, setBankAccount] = useState(props.user.bank_account);
  const [accountHolderName, setAccountHolderName] = useState(props.user.account_holder_name);
  const [anniversary, setAnniversary] = useState(props.user.anniversary);
  const [ifscCode, setIfscCode] = useState(props.user.ifsc_code);
  const [identity, setIdentity] = useState(props.user.identity);
  const [bloodGroup, setBloodGroup] = useState(props.user.blood_group);
  const [father, setFather] = useState(props.user.father);
  const [mother, setMother] = useState(props.user.mother);
  const [emergency, setEmergency] = useState(props.user.emergency);
  const [emergencyMobile, setEmergencyMobile] = useState(props.user.emergency_mobile);
  const [personalEmail, setPersonalEmail] = useState(props.user.personal_email);
  const [personalMobile, setPersonalMobile] = useState(props.user.personal_mobile);
  const [maritalStatus, setMaritalStatus] = useState(props.user.marital_status);
  const [religion, setReligion] = useState(props.user.religion);
  const [remark, setRemark] = useState(props.user.remark);

  
  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    axios.post(global.config.api_server+'user',createFormData({
      anniversary: anniversary,
      blood_group: bloodGroup,
      father: father,
      mother: mother,
      emergency: emergency,
      emergency_mobile: emergencyMobile,
      personal_email: personalEmail,
      personal_mobile: personalMobile,        
      pan: pan,
      bank: bank,
      bank_branch: bankBranch,
      bank_account: bankAccount,
      account_holder_name: accountHolderName,
      marital_status: maritalStatus,
      religion: religion,
      ifsc_code: ifscCode,
      identity: identity,
      remark: remark,
      token: localStorage.getItem("token"),
      user_id: props.id,
      task: 'additional'
    })).then(res => {
      setLoading(false);
      if(res.status === 200 && res.data.status === "success"){
        alertService.success('User updated');
      }else{
        alertService.error(res.data.error);
      }
    }).catch(err => {
      alertService.error(err);
      setLoading(false);
    });
  }

  const date = new Date(anniversary);
  let anniversary_date = undefined;
  if(date.getFullYear() > 1900){
    anniversary_date = date.getFullYear() + "-";
    if((date.getMonth() + 1) < 10){
      anniversary_date += "0";
    }
    anniversary_date += (date.getMonth() + 1);
    anniversary_date += "-";
    if(date.getDate() < 10){
      anniversary_date += "0";
    }
    anniversary_date += date.getDate();
  }
  return(
    <div className="card shadow mb-4">
      <div className="card-header py-3">            
        <button onClick={(e) => handleSubmit(e)} className={'btn btn-icon-split float-right btn-'+theme()}>
          <span className="icon text-white-50">
            <i className="fas fa-save"></i>
          </span>
          <span className="text">Save</span>
        </button>
        <h6 className={'mt-2 font-weight-bold text-'+theme()}>User - Additional Information</h6>
      </div>
      <div className="card-body">
        <div className="row">
          {loading === true ? (
            <Progress label='Saving' small={true}/>
          ):null}
          <div className="col-md-6">
            <div className="form-group">
              <label>Date of Anniversary</label>
              <input type="date" value={anniversary_date} maxLength="50" name="anniversary" className="form-control" id="anniversary" placeholder="Enter Anniversary" onChange={(e) => setAnniversary(e.target.value)}/>
            </div>
            <div className="form-group">
              <label>Blood Group</label>
              <input type="text" value={bloodGroup} maxLength="5" name="bloodGroup" className="form-control" id="blood_group" placeholder="Enter Blood Group" onChange={(e) => setBloodGroup(e.target.value)}/>
            </div>
            <div className="form-group">
              <label>Father Name</label>
              <input type="text" value={father} maxLength="50" name="father" className="form-control" id="father" placeholder="Enter Father Name" onChange={(e) => setFather(e.target.value)}/>
            </div>
              <div className="form-group">
              <label>Mother Name</label>
              <input type="text" value={mother} maxLength="50" name="mother" className="form-control" id="mother" placeholder="Enter Mother Name" onChange={(e) => setMother(e.target.value)}/>
            </div>
            <div className="form-group">
              <label>Marital Status</label>
              <select name="marital_status" className="form-control" value={maritalStatus} onChange={(e) => setMaritalStatus(e.target.value)}>
                <option>--Select--</option>
                <option value="Married">Married</option>
                <option value="Single">Single</option>
                <option value="Divorced">Divorced</option>
                <option value="Widow">Widow</option>
              </select>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label>Emergency Contact Name</label>
              <input type="text" value={emergency} maxLength="50" name="emergency" className="form-control" id="emergency" placeholder="Enter Emergency Contact" onChange={(e) => setEmergency(e.target.value)}/>
            </div>
            <div className="form-group">
              <label>Emergency Contact Number</label>
              <input type="text" value={emergencyMobile} maxLength="50" name="emergencyMobile" className="form-control" id="emergency_mobile" placeholder="Enter Emergency Contact Number" onChange={(e) => setEmergencyMobile(e.target.value)}/>
            </div>
            <div className="form-group">
              <label>Personal Email</label>
              <input type="email" value={personalEmail} maxLength="50" name="personalEmail" className="form-control" id="personal_email" placeholder="Enter Personal Email" onChange={(e) => setPersonalEmail(e.target.value)}/>
            </div>
            <div className="form-group">
              <label>Personal Mobile</label>
              <input type="text" value={personalMobile} maxLength="15" name="personalMobile" className="form-control" id="personal_mobile" placeholder="Enter Personal Mobile" onChange={(e) => setPersonalMobile(e.target.value)}/>
            </div>
            <div className="form-group">
              <label>Religion</label>
              <select name="religion" className="form-control" value={religion} onChange={(e) => setReligion(e.target.value)}>
                <option>--Select--</option>
                <option value="Hinduism">Hinduism</option>
                <option value="Islam">Islam</option>
                <option value="Christianity">Christianity</option>
                <option value="Sikhism">Sikhism</option>
                <option value="Buddhism">Buddhism</option>
                <option value="Jainism">Jainism</option>
                <option value="Jainism">Jainism</option>
                <option value="Atheism">Atheism</option>
                <option value="Others">Others</option>
              </select>
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-group">
              <label>Identity Proof Document Name & Number</label>
              <input type="text" value={identity} maxLength="50" name="identity" className="form-control" id="identity" placeholder="Passport - xxxxxx" onChange={(e) => setIdentity(e.target.value)}/>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <hr/>
            <div className="form-group">
              <label><strong>Banking Details</strong></label>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label>Pan Card</label>
              <input type="text" value={pan} maxLength="20" name="pan" className="form-control" id="pan" placeholder="Enter Pan Card" onChange={(e) => setPan(e.target.value)}/>
            </div>
            <div className="form-group">
              <label>Bank Name</label>
              <input type="text" value={bank} maxLength="50" name="bank" className="form-control" id="bank" placeholder="Enter Bank Name" onChange={(e) => setBank(e.target.value)}/>
            </div>
            <div className="form-group">
              <label>Bank Branch</label>
              <input type="text" value={bankBranch} maxLength="50" name="bankBranch" className="form-control" id="bank_branch" placeholder="Enter Bank Branch" onChange={(e) => setBankBranch(e.target.value)}/>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label>Bank Account</label>
              <input type="text" value={bankAccount} maxLength="20" name="bankAccount" className="form-control" id="bank_account" placeholder="Enter Bank Account" onChange={(e) => setBankAccount(e.target.value)}/>
            </div>
            <div className="form-group">
              <label>Account Holder Name</label>
              <input type="text" value={accountHolderName} maxLength="50" name="account_holder_name" className="form-control" placeholder="Enter Account Holder Name" onChange={(e) => setAccountHolderName(e.target.value)}/>
            </div>
            <div className="form-group">
              <label>Bank IFSC Code</label>
              <input type="text" value={ifscCode} maxLength="50" name="ifscCode" className="form-control" id="ifsc_code" placeholder="Enter Bank IFSC Code" onChange={(e) => setIfscCode(e.target.value)}/>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <hr/>
            <div className="form-group">
              <label>Remark</label>
              <textarea value={remark} maxLength="200" name="remark" className="form-control" id="remark" placeholder="Enter Remark" onChange={(e) => setRemark(e.target.value)}/>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UserAdditionalInfo;