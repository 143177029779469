import React, {useState, useEffect, useRef} from 'react';
import { useParams } from 'react-router';
import axios from 'axios';
import '../config.js';

import { theme, createFormData } from '../services/helper.js';
import { alertService } from '../services/alert.service.js';
import FestivalListItem from '../components/festivalListItem.js';
import Progress from '../components/progress.js';
import { Paginator } from '../components/paginator.js';

function Festivals() {
  const [search, setSearch] = useState('');
  const [filteredFestivals, setFilteredFestivals] = useState([]);
  const [festivals, setFestivals] = useState([]);
  const [offset, setOffset] = useState(0);
  const [loading, setLoading] = useState(true);
  
  let searchForm = useRef();
  let createForm = useRef();
  let formData = {};

  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    formData[name] = value;
  }
  
  const handleSearch = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    let currentSearch = search;
    if(name == "search"){
      setSearch(value);
      currentSearch = value;
    }
    triggerSearch(currentSearch);
  }

  const triggerSearch = (currentSearch) => {
    const filtered = [];
    for(let i = 0;i<festivals.length;i++){
      if((currentSearch === undefined || currentSearch.trim() === '' || festivals[i].subject.indexOf(currentSearch) >= 0 || festivals[i].festival_date.indexOf(currentSearch) >= 0)){
        filtered.push(festivals[i]);
      }
    }
    setFilteredFestivals(filtered);
  }
  
  const resetSearch = (event) => {
    searchForm.current.reset();
    setSearch('');
    setFilteredFestivals(festivals);
  }
  
  const handleSubmit = (event) => {
    event.preventDefault();
    if(formData.festival_date && formData.subject){
      setLoading(true);
      axios.post(global.config.api_server+'festival',createFormData({
        festival_date: formData.festival_date,
        subject: formData.subject,
        task:"create",
        token: localStorage.getItem("token")
      })).then(res => {
        if(res.status === 200 && res.data.status === "success"){
          alertService.success('Festival added');
          setTimeout(function(){
            window.location.reload();
          }, 1000);
        }else{
          alertService.error(res.data.error);
        }
        setLoading(false);
      }).catch(err => {
        alertService.error(err);
        setLoading(false);
      });
    }else{
      alertService.error('Fill required fields');
      setLoading(false);
    }
  }
  
  useEffect(()=>{
    let uri = global.config.api_server+'festival?token='+localStorage.getItem("token");
    axios.get(uri)
    .then(res => {
      if(res.status === 200 && res.data.status === "success"){
        const temp = res.data.festivals;
        setFestivals(temp);
        setFilteredFestivals(temp);
        setLoading(false);
      }
    }).catch(err => {
      alertService.error(err);
    });
  }, []);
  
  const onPageChange = (index_start) => {
    setOffset(index_start);
  }

  let total = 0;
  let counter = 0;
  let offsetCounter = 0;
  const paginated_festivals = [];
  for (const u of filteredFestivals) {
    total++;
    offsetCounter++;
    if (offsetCounter > offset && counter < global.config.items_per_page){
      counter++;
      paginated_festivals.push(u);
    }
  }
  const salutations = global.config.salutations.split(",");
  if(loading){
    return <Progress/>;
  }else{
    return(
      <div className="container-fluid">
        <div className="card shadow mb-4">
          <div className="card-header py-3">
            <a href="#" className={'btn btn-icon-split float-right btn-'+theme()} data-toggle="modal" data-target="#createFestivalModal">
              <span className="icon text-white-50">
                <i className="fas fa-magic"></i>
              </span>
              <span className="text">Add New Festival</span>
            </a>
            <form ref={searchForm} className="form-inline">
              <h6 className={'form-group mr-3 font-weight-bold text-'+theme()}>Festivals</h6>
              <div className="form-group mr-3">
                <input type="search" name="search" className="form-control" value={search} placeholder="Search" onChange={handleSearch}/>
              </div>
              {search &&
              <div className="form-group mr-3">
                <a href="#" className="btn btn-default btn-icon-split" onClick={resetSearch}>
                  <span className="icon">
                    <i className="fas fa-times"></i>
                  </span>
                </a>
              </div>
              }
            </form>
          </div>
          <div className="card-body">
            <div className="table-responsive">
              <div className="row">
                <div className="col-sm-12">
                  <table className="table table-bordered" width="100%">
                    <thead>
                      <tr>
                        <th>Subject</th>
                        <th>Date</th>
                        <th>Status</th>
                        <th>Attachment</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {paginated_festivals.map(festival=>
                        <FestivalListItem key={festival.festival_id} info={festival}/>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
              <Paginator
                itemCount={total}
                itemsPerPage={global.config.items_per_page}
                showNextPages={2}
                showPrevPages={2}
                filterTotal={filteredFestivals.length}
                grandTotal={festivals.length}
                onClick={onPageChange.bind(this)}
              />
            </div>
          </div>
        </div>
        <div className="modal fade" id="createFestivalModal" tabIndex="-1" role="dialog" aria-labelledby="create-popup" aria-hidden="true">
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="create-popup">Create New Festival</h5>
                <button className="close" type="button" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <form ref={createForm}>
                <div className="modal-body">
                  <div className="form-group">
                    <label>Subject *</label>
                    <input type="text" maxLength="50" name="subject" className="form-control" id="subject" placeholder="Enter First Name" onChange={handleInputChange}/>
                  </div>
                  <div className="form-group">
                    <label>Date *</label>
                    <input type="date" maxLength="50" name="festival_date" className="form-control" id="festival_date" placeholder="Enter Date" onChange={handleInputChange}/>
                  </div>
                  {loading === true ? (
                    <Progress label='Creating..' small={true}/>
                  ):null}
                </div>
                <div className="modal-footer">
                  <button className="btn btn-default" type="reset" data-dismiss="modal">Close</button>
                  <button className={'btn btn-'+theme()} type="button" onClick={handleSubmit}>Submit</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Festivals;