import React from 'react';
import {Link} from 'react-router-dom';
import axios from 'axios';
import '../config.js';
import { theme, createFormData } from '../services/helper.js';
import { alertService } from '../services/alert.service.js';

function DocumentListItem(props) {


  const handleDelete = (event) => {
    event.preventDefault();
    if(props.info.document_id && props.info.uri){
      axios.post(global.config.api_server+'document',createFormData({
        path: props.info.uri,
        id: props.info.document_id,
        task:"delete",
        token: localStorage.getItem("token")
      })).then(res => {
        if(res.status === 200 && res.data.status === "success"){
          alertService.success('Document Deleted');
          setTimeout(function(){
            window.location.reload();
          }, 1000);
        }else{
          alertService.error(res.data.error);
        }
      }).catch(err => {
        alertService.error(err);
      });
    }else{
      alertService.error('Error deleting document');
    }
  }


  let showcase = <a href={global.config.asset_server + props.info.uri} className={'btn btn-icon-split btn-'+theme()}>
                  <span className="icon text-white-50">
                    <i className="fas fa-download"></i>
                  </span>
                  <span className="text">Download</span>
                </a>;
  if(props.info.uri.toUpperCase().endsWith('.JPG') || props.info.uri.toUpperCase().endsWith('.JPEG') || props.info.uri.toUpperCase().endsWith('.PNG') || props.info.uri.toUpperCase().endsWith('.GIF')){
    showcase = <a href={props.info.uri}>
                  <img src={global.config.asset_server + props.info.uri} width="auto" height="100px"/>
                </a>
  }
  let deleteButton = <button className={'btn btn-icon-split disabled btn-'+theme()}>
                      <span className="icon text-white-50">
                        <i className="fas fa-trash"></i>
                      </span>
                      <span className="text">Delete</span>
                    </button>;
  if(parseInt(localStorage.getItem("role")) >= 3){
    deleteButton = <button onClick={handleDelete} className={'btn btn-icon-split btn-'+theme()}>
                    <span className="icon text-white-50">
                      <i className="fas fa-trash"></i>
                    </span>
                    <span className="text">Delete</span>
                  </button>;
  }
  return(
        <tr>
          <td>{showcase}</td>
          <td>{props.info.label}</td>
          <td>{props.info.type}</td>
          <td>{props.info.upload_date}</td>
          <td>{deleteButton}</td>
        </tr>
  )
}

export default DocumentListItem;