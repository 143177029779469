import React, {useState, useEffect, useRef} from 'react';
import axios from 'axios';
import '../config.js';
import RichTextEditor from '../components/rte.js';
import { theme, createFormData } from '../services/helper.js';
import { alertService } from '../services/alert.service.js';
import Progress from '../components/progress.js';
import ReceiptListItem from './receiptListItem.js';

function InvoiceBasicInfo(props){
  const [loading, setLoading] = useState(false);
  const [description, setDescription] = useState(props.invoice.description);
  const [rate, setRate] = useState(props.invoice.rate);
  const [pax, setPax] = useState(props.invoice.pax);
  const [tax, setTax] = useState(props.invoice.tax);
  const [otp, setOtp] = useState(null);
  const [notifySms, setNotifySms] = useState(0);
  const [notifyMail, setNotifyMail] = useState(0);
  const userRole = localStorage.getItem("role");
  
  const handleSubmit = (event) => {
    event.preventDefault();
    if(rate && rate > 0 && pax && pax > 0 && description && (userRole == 10 || otp)){
      setLoading(true);
      axios.post(global.config.api_server+'invoice',createFormData({
        description: description,
        rate: rate,
        pax: pax,
        tax: tax,
        token: localStorage.getItem("token"),
        id: props.id,
        task: 'edit',
        notifySms: notifySms,
        notifyMail: notifyMail,
        otp: otp
      })).then(res => {
        setLoading(false);
        if(res.status === 200 && res.data.status === 'success'){
          alertService.success('Invoice updated');
        }else{
          alertService.error(res.data.error);
        }
      }).catch(err => {
        alertService.error(err);
        setLoading(false);
      });
    }else{
      alertService.error('Some fields are required');
    }
  }

  const handleNotifySms = (event) =>{
    if(event.target.checked){
      setNotifySms(1);
    }else{
      setNotifySms(0);
    }
  }

  const handleNotifyMail = (event) =>{
    if(event.target.checked){
      setNotifyMail(1);
    }else{
      setNotifyMail(0);
    }
  }

  const reqPermissionOtp = (event) => {
    if(userRole < 10){
      const payloadType = "INVOICE_UPDATE";
      axios.get(global.config.api_server+'permission?payloadType='+payloadType+'&payload='+props.id+'&description='+props.invoice.invoice_id+' of '+props.invoice.client_info.email+'&token='+localStorage.getItem("token")).then(res => {
        if(res.status === 200 && res.data.status === "success"){
          alertService.success('OTP Requested');
        }else{
          alertService.error(res.data.error);
        }
      }).catch(err => {
        alertService.error(err);
      });
    }
  }

  const balance = parseFloat(props.invoice.amount) - parseFloat(props.invoice.paid);

  return(
    <div>
      <div className="card shadow mb-4">
        <div className="card-header py-3">
          {userRole == 10 &&
            <button onClick={(e) => handleSubmit(e)} className={'btn btn-icon-split float-right btn-'+theme()}>
              <span className="icon text-white-50">
                <i className="fas fa-save"></i>
              </span>
              <span className="text">Save</span>
            </button>
          }
          {userRole != 10 && 
            <button className={'btn btn-icon-split float-right btn-'+theme()} data-toggle="modal" data-target="#updateInvoiceModal" onClick={reqPermissionOtp}>
              <span className="icon text-white-50">
                <i className="fas fa-save"></i>
              </span>
              <span className="text">Save</span>
            </button>
          }
          <h6 className={'mt-2 font-weight-bold text-'+theme()}>Proforma Invoice Detail</h6>
        </div>
        <div className="card-body">
          <div className="row">
            {loading === true ? (
              <Progress label='Saving' small={true}/>
            ):null}
            <div className="col-md-6">
              <div className="form-group">
                <label>Proforma Invoice id</label>
                <input type="text" value={props.invoice.invoice_id} disabled={true} className="form-control"/>
              </div>
              <div className="form-group">
                <label>Total amount</label>
                <input type="text" value={props.invoice.amount} disabled={true} className="form-control"/>
              </div>
              <div className="form-group">
                <label>Amount paid</label>
                <input type="text" value={props.invoice.paid} disabled={true} className="form-control"/>
              </div>
              <div className="form-group">
                <label>Balance</label>
                <input type="text" value={balance} disabled={true} className="form-control"/>
              </div>
              <div className="form-group">
                <input type="checkbox" onChange={(e) => handleNotifyMail(e)}/> Send Mail &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<input type="checkbox" onChange={(e) => handleNotifySms(e)}/> Send SMS
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>Number of Pax *</label>
                <input type="number" value={pax} name="pax" className="form-control" id="pax" placeholder="Enter pax" onChange={(e) => setPax(e.target.value)}/>
              </div>
              <div className="form-group">
                <label>Rate per pax *</label>
                <input type="number" value={rate} name="rate" className="form-control" id="rate" placeholder="Enter Rate" onChange={(e) => setRate(e.target.value)}/>
              </div>
              <div className="form-group">
                <label>Services *</label>
                <textarea className="form-control textarea-xlg" value={description} onChange={(e)=>setDescription(e.target.value)}></textarea>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <hr/>
              <div className="form-group">
                <label><strong>Stats</strong></label>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>Updated</label>
                <input type="text" disabled={true} value={props.invoice.updation_date} className="form-control"/>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>Created</label>
                <input type="text" disabled={true} value={props.invoice.creation_date} className="form-control"/>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="card shadow mb-4">
        <div className="card-header py-3">
          <h6 className={'mt-2 font-weight-bold text-'+theme()}>Receipts</h6>
        </div>
        <div className="card-body">
        <div className="table-responsive">
          <div className="row">
            {loading === true ? (
              <Progress label='Loading..' small={true}/>
            ):null}
              <div className="col-sm-12">
                <table className="table table-bordered" width="100%">
                  <thead>
                    <tr>
                      <th>Receipt Id</th> 
                      <th>Additional Note</th>
                      <th>Amount</th>
                      <th>Created On</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {props.invoice.receipts.map(receipt =>
                      <ReceiptListItem info={receipt} key={receipt.receipt_id} type="client"/>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      {userRole != 10 && 
        <div className="modal fade" id="updateInvoiceModal" tabIndex="-1" role="dialog" aria-labelledby="update-invoice-popup" aria-hidden="true">
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="update-invoice-popup">Updating Invoice</h5>
                <button className="close" type="button" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="form-group">
                  <label>Enter OTP to continue.</label>
                  <input type="text" maxLength="50" name="otp" value={otp} className="form-control" id="otp" placeholder="Enter OTP" onChange={(e) => setOtp(e.target.value)}/>
                </div>
              </div>
              <div className="modal-footer">
                <button className="btn btn-default" type="button" data-dismiss="modal">Cancel</button>
                <button className={'btn ml-3 btn-'+theme()} onClick={(e) => handleSubmit(e)}  data-dismiss="modal">Save</button>
              </div>
            </div>
          </div>
        </div>
      }
    </div>
  )
}

export default InvoiceBasicInfo;