import React, {useState, useEffect, useRef} from 'react';
import axios from 'axios';
import '../config.js';

import { theme, createFormData, sortObj } from '../services/helper.js';
import { alertService } from '../services/alert.service.js';
import ClientListItem from '../components/clientListItem.js';
import Progress from '../components/progress.js';
import { Paginator } from '../components/paginator.js';
import Sort from '../components/sort.js';

function Clients() {
  const [search, setSearch] = useState('');
  const [sortBy, setSortBy] = useState('first_name');
  const [sortOrder, setSortOrder] = useState(true);
  const [searchType, setSearchType] = useState('all');
  const [filteredClients, setFilteredClients] = useState([]);
  const [clients, setClients] = useState([]);
  const [offset, setOffset] = useState(0);
  const [loading, setLoading] = useState(true);
  const [archive, setArchive] = useState(0);
  
  let searchForm = useRef();
  let createForm = useRef();
  let formData = {};

  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    formData[name] = value;
  }

  const handleArchive = (event) =>{
    if(event.target.checked){
      setArchive(1);
    }else{
      setArchive(0);
    }
  }
  
  const handleSearch = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    let currentSearch = search;
    if(name === "search"){
      setSearch(value);
      currentSearch = value;
    }
    const filtered = [];
    for(let i = 0;i<clients.length;i++){
      if(searchType === 'email' && (currentSearch === undefined || currentSearch.trim() === '' || clients[i].email.indexOf(currentSearch) >= 0)){
        filtered.push(clients[i]);
      } else if(searchType === 'mobile' && (currentSearch === undefined || currentSearch.trim() === '' || clients[i].mobile.indexOf(currentSearch) >= 0)){
        filtered.push(clients[i]);
      } else if(searchType === 'name' && (currentSearch === undefined || currentSearch.trim() === '' || clients[i].first_name.indexOf(currentSearch) >= 0 || clients[i].last_name.indexOf(currentSearch) >= 0 || clients[i].middle_name.indexOf(currentSearch) >= 0)){
        filtered.push(clients[i]);
      } else if(searchType === 'type' && (currentSearch === undefined || currentSearch.trim() === '' || clients[i].type.indexOf(currentSearch) >= 0)){
        filtered.push(clients[i]);
      }else if(searchType === 'all' && (currentSearch === undefined || currentSearch.trim() === '' || clients[i].first_name.indexOf(currentSearch) >= 0 || clients[i].last_name.indexOf(currentSearch) >= 0 || clients[i].middle_name.indexOf(currentSearch) >= 0 || clients[i].mobile.indexOf(currentSearch) >= 0 || clients[i].email.indexOf(currentSearch) >= 0 || clients[i].type.indexOf(currentSearch) >= 0)){
        filtered.push(clients[i]);
      }
    }
    setFilteredClients(sortObj(filtered, sortBy, sortOrder));
  }

  const updateSortParam = (e, field) => {
    let newSearchOrder = true;
    if(field === sortBy){
      newSearchOrder = !sortOrder;
    }else{
      setSortBy(field);
    }
    const sortList = filteredClients;
    setSortOrder(newSearchOrder);
    setFilteredClients(sortObj(sortList, field, newSearchOrder));
  }
  
  const resetSearch = (event) => {
    searchForm.current.reset();
    setSearch('');
    setSearchType('all');
    setFilteredClients(sortObj(clients, sortBy, sortOrder));
  }
  
  const handleSubmit = (event) => {
    event.preventDefault();
    if(formData.first_name && formData.last_name && formData.email && formData.mobile && formData.dob && formData.pan && formData.city && formData.state && formData.pincode && formData.address){
      setLoading(true);
      if(formData.salutation === undefined){
        formData.salutation = "Mr.";
      }
      if(formData.type === undefined){
        formData.type = "FIT";
      }
      axios.post(global.config.api_server+'client',createFormData({
        first_name: formData.first_name,
        last_name: formData.last_name,
        middle_name: formData.middle_name,
        email: formData.email,
        mobile: formData.mobile,
        salutation: formData.salutation,
        type: formData.type,
        dob: formData.dob,
        anniversary: formData.anniversary,
        pan: formData.pan,
        gst: formData.gst,
        city: formData.city,
        address: formData.address,
        state: formData.state,
        pincode: formData.pincode,
        task:"create",
        token: localStorage.getItem("token")
      })).then(res => {
        if(res.status === 200 && res.data.status === "success"){
          alertService.success('New client created');
          setTimeout(function(){
            window.location.reload();
          }, 1000);
        }else{
          alertService.error(res.data.error);
        }
        setLoading(false);
      }).catch(err => {
        alertService.error(err);
        setLoading(false);
      });
    }else{
      alertService.error('Fill required fields');
      setLoading(false);
    }
  }
  
  useEffect(()=>{
    let uri = global.config.api_server+'clients?archive='+archive+'&token='+localStorage.getItem("token");
    axios.get(uri)
    .then(res => {
      if(res.status === 200 && res.data.status === "success"){
        const temp = res.data.clients;
        setClients(temp)
        setFilteredClients(sortObj(temp, sortBy, sortOrder));
        setLoading(false);
      }
    }).catch(err => {
      alertService.error(err);
    });
  }, [archive]);
  
  const onPageChange = (index_start) => {
    setOffset(index_start);
  }

  let total = 0;
  let counter = 0;
  let offsetCounter = 0;
  const paginated_clients = [];
  for (const u of filteredClients) {
    total++;
    offsetCounter++;
    if (offsetCounter > offset && counter < global.config.items_per_page){
      counter++;
      paginated_clients.push(u);
    }
  }
  const salutations = global.config.salutations.split(",");
  const clientTypes = global.config.clientType.split(",");
  if(loading){
    return <Progress/>;
  }else{
    return(
      <div className="container-fluid">
        <div className="card shadow mb-4">
          <div className="card-header py-3">
            <a href="#" className={'btn btn-icon-split float-right btn-'+theme()} data-toggle="modal" data-target="#createClientModal">
              <span className="icon text-white-50">
                <i className="fas fa-user-plus"></i>
              </span>
              <span className="text">Create New Client</span>
            </a>
            <div class="form-group float-right mr-2 mt-2"><input type="checkbox" onChange={(e) => handleArchive(e)}/> Include Archived</div>
            <form ref={searchForm} className="form-inline">
              <h6 className={'form-group mr-3 font-weight-bold text-'+theme()}>Clients</h6>
              <div className={"form-group mr-3"}>
                <select name="searchType" className="form-control" value={searchType} id="searchType" onChange={(e) => setSearchType(e.target.value)}>
                  <option value="all">All</option>
                  <option value="name">Name</option>
                  <option value="email">Email</option>
                  <option value="mobile">Mobile</option>
                  <option value="type">Type</option>
                </select>
              </div>
              <div className="form-group mr-3">
                <input type="search" name="search" className="form-control" placeholder="Search" onChange={handleSearch}/>
              </div>
              {search &&
              <div className="form-group mr-3">
                <a href="#" className="btn btn-default btn-icon-split" onClick={resetSearch}>
                  <span className="icon">
                    <i className="fas fa-times"></i>
                  </span>
                </a>
              </div>
              }
            </form>
          </div>
          <div className="card-body">
            <div className="table-responsive">
              <div className="row">
                <div className="col-sm-12">
                  <table className="table table-bordered" width="100%">
                    <thead>
                      <tr>
                        <th onClick={(e) => updateSortParam(e,'first_name')}>Name <Sort field="first_name" sortBy={sortBy} sortOrder={sortOrder}/></th>
                        <th onClick={(e) => updateSortParam(e,'email')}>Email <Sort field="email" sortBy={sortBy} sortOrder={sortOrder}/></th>
                        <th onClick={(e) => updateSortParam(e,'mobile')}>Mobile <Sort field="mobile" sortBy={sortBy} sortOrder={sortOrder}/></th>
                        <th onClick={(e) => updateSortParam(e,'type')}>Type<Sort field="type" sortBy={sortBy} sortOrder={sortOrder}/></th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {paginated_clients.map(client=>
                        <ClientListItem key={client.client_id} info={client}/>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
              <Paginator
                itemCount={total}
                itemsPerPage={global.config.items_per_page}
                showNextPages={2}
                showPrevPages={2}
                filterTotal={filteredClients.length}
                grandTotal={clients.length}
                onClick={onPageChange.bind(this)}
              />
            </div>
          </div>
        </div>
        <div className="modal fade" id="createClientModal" tabIndex="-1" role="dialog" aria-labelledby="create-popup" aria-hidden="true">
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="create-popup">Create New Client</h5>
                <button className="close" type="button" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <form ref={createForm}>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-md-6 col-lg-4">
                      <div className="form-group">
                        <label>Salutation*</label>
                        <select name="salutation" className="form-control" id="salutation" onChange={handleInputChange}>
                          {salutations.map(d =>
                              <option value={d} key={d}>{d}</option>
                          )}
                        </select>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-group">
                        <label>Date of Birth*</label>
                        <input type="date" maxLength="50" name="dob" className="form-control" id="dob" placeholder="Enter DOB" onChange={handleInputChange}/>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-group">
                        <label>Anniversary Date</label>
                        <input type="date" maxLength="50" name="anniversary" className="form-control" id="anniversary" placeholder="Enter Anniversary" onChange={handleInputChange}/>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-group">
                        <label>First Name*</label>
                        <input type="text" maxLength="50" name="first_name" className="form-control" id="first_name" placeholder="Enter First Name" onChange={handleInputChange}/>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-group">
                        <label>Middle Name</label>
                        <input type="text" maxLength="50" name="middle_name" className="form-control" id="middle_name" placeholder="Enter Middle Name" onChange={handleInputChange}/>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-group">
                        <label>Last Name*</label>
                        <input type="text" maxLength="50" name="last_name" className="form-control" id="last_name" placeholder="Enter Last Name" onChange={handleInputChange}/>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-group">
                        <label>GST</label>
                        <input type="text" maxLength="50" name="gst" className="form-control" id="gst" placeholder="Enter GST" onChange={handleInputChange}/>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-group">
                        <label>PAN*</label>
                        <input type="text" maxLength="20" name="pan" className="form-control" id="pan" placeholder="Enter PAN" onChange={handleInputChange}/>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-group">
                        <label>Type*</label>
                        <select name="type" className="form-control" id="type" onChange={handleInputChange}>
                          {clientTypes.map(d =>
                              <option value={d} key={d}>{d}</option>
                          )}
                        </select>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-group">
                        <label>Email*</label>
                        <input type="email" maxLength="50" name="email" className="form-control" id="email" placeholder="Enter Email" onChange={handleInputChange}/>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-group">
                        <label>Mobile*</label>
                        <input type="text" maxLength="15" name="mobile" className="form-control" id="mobile" placeholder="Enter Mobile" onChange={handleInputChange}/>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-group">
                        <label>City*</label>
                        <input type="text" maxLength="50" name="city" className="form-control" id="city" placeholder="Enter City" onChange={handleInputChange}/>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-group">
                        <label>State*</label>
                        <input type="text" maxLength="50" name="state" className="form-control" id="state" placeholder="Enter State" onChange={handleInputChange}/>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-group">
                        <label>Pincode*</label>
                        <input type="text" maxLength="20" name="pincode" className="form-control" id="pincode" placeholder="Enter Pincode" onChange={handleInputChange}/>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <label>Address*</label>
                        <textarea type="text" maxLength="100" name="address" className="form-control" id="address" placeholder="Enter Address" onChange={handleInputChange}></textarea>
                      </div>
                    </div>
                  </div>
                  {loading === true ? (
                    <Progress label='Creating..' small={true}/>
                  ):null}
                </div>
                <div className="modal-footer">
                  <button className="btn btn-default" type="reset" data-dismiss="modal">Close</button>
                  <button className={'btn btn-'+theme()} type="button" onClick={handleSubmit}>Submit</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Clients;