import React, {useState, useEffect, useRef} from 'react';
import axios from 'axios';
import '../config.js';

import { alertService } from '../services/alert.service.js';
import { theme, createFormData } from '../services/helper.js';
import Progress from '../components/progress.js';

function Export() {
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState('invoice');
  const [criteria, setCriteria] = useState('range');
  const [otp, setOtp] = useState(null);
  const [from, setFrom] = useState();
  const [to, setTo] = useState();
  const [month, setMonth] = useState(0);
  const [exportFile, setExportFile] = useState(null);
  const [year, setYear] = useState(2024);

  const handleSubmit = (event) =>{
    event.preventDefault();
    setLoading(true);
    axios.post(global.config.api_server+'export',createFormData({
      year: year,
      month: month,
      to: to, 
      from: from,
      criteria: criteria,
      token: localStorage.getItem("token"),
      type: type,
      otp: otp
    })).then(res => {
      setLoading(false);
      if(res.status === 200 && res.data.status === "success"){
        alertService.success('Data compiled successfully, download the file from link below');
        setExportFile(res.data.output);
      }else{
        alertService.error(res.data.error);
      }
    }).catch(err => {
      alertService.error(err);
      setLoading(false);
    });
  }

  const userRole = localStorage.getItem("role");

  const reqPermissionOtp = (event) => {
    if(userRole < 10){
      const payloadType = "EXPORT";
      axios.get(global.config.api_server+'permission?payloadType='+payloadType+'&payload='+type+'&description='+type+'&token='+localStorage.getItem("token")).then(res => {
        if(res.status === 200 && res.data.status === "success"){
          alertService.success('OTP Requested');
        }else{
          alertService.error(res.data.error);
        }
      }).catch(err => {
        alertService.error(err);
      });
    }
  }

  const dateFrom = new Date(from);
  let from_date = undefined;
  if(dateFrom.getFullYear() > 1900){
    from_date = dateFrom.getFullYear() + "-";
    if((dateFrom.getMonth() + 1) < 10){
      from_date += "0";
    }
    from_date += (dateFrom.getMonth() + 1);
    from_date += "-";
    if(dateFrom.getDate() < 10){
      from_date += "0";
    }
    from_date += dateFrom.getDate();
  }

  const dateTo = new Date(to);
  let to_date = undefined;
  if(dateTo.getFullYear() > 1900){
    to_date = dateTo.getFullYear() + "-";
    if((dateTo.getMonth() + 1) < 10){
      to_date += "0";
    }
    to_date += (dateTo.getMonth() + 1);
    to_date += "-";
    if(dateTo.getDate() < 10){
      to_date += "0";
    }
    to_date += dateTo.getDate();
  }

  const keys = global.config.settings_token.split(",");
    return(
      <div className="container-fluid">
        <div className="card shadow mb-4">
          <div className="card-header py-3">
            {userRole == 10 &&
              <button onClick={(e) => handleSubmit(e)} className={'btn btn-icon-split float-right btn-'+theme()}>
                <span className="icon text-white-50">
                  <i className="fas fa-file-excel"></i>
                </span>
                <span className="text">Generate</span>
              </button>
            }
            {userRole != 10 && 
              <button className={'btn btn-icon-split float-right btn-'+theme()} data-toggle="modal" data-target="#exportModal" onClick={reqPermissionOtp}>
                <span className="icon text-white-50">
                  <i className="fas fa-file-excel"></i>
                </span>
                <span className="text">Generate</span>
              </button>
            }
            <h6 className={'form-group mr-3 font-weight-bold text-'+theme()}>Export</h6>
          </div>
          <div className="card-body">
            <div className='row'>
              <div className='col-md-6'>
                <div className="form-group">
                  <label>Data Type</label>
                  <select name="type" className="form-control" value={type} id="type" onChange={(e) => setType(e.target.value)}>
                    <option value="invoice">Proforma Invoice</option>
                    <option value="receipt">Receipt</option>
                    <option value="credit">Credit Note</option>
                  </select>
                </div>
                <div className="form-group">
                  <label>Criteria</label>
                  <select name="criteria" className="form-control" value={criteria} id="criteria" onChange={(e) => setCriteria(e.target.value)}>
                    <option value="range">Date Range</option>
                    <option value="monthYear">Month & Year</option>
                  </select>
                </div>
              </div>
              {criteria == 'range' &&
                <div className='col-md-6'>
                  <div className="form-group">
                    <label>Date Range - From</label>
                    <input type="date" value={from_date} maxLength="50" name="from" className="form-control" id="from" placeholder="From" onChange={(e) => setFrom(e.target.value)}/>
                  </div>
                  <div className="form-group">
                    <label>Date Range - To</label>
                    <input type="date" value={to_date} maxLength="50" name="to" className="form-control" id="to" placeholder="To" onChange={(e) => setTo(e.target.value)}/>
                  </div>
                </div>
              }
              {criteria == 'monthYear' &&
                <div className='col-md-6'>
                  <div className="form-group">
                    <label>Month</label>
                    <select name="month" className="form-control" value={month} id="month" onChange={(e) => setMonth(e.target.value)}>
                      <option value="0">None</option>
                      <option value="1">January</option>
                      <option value="2">February</option>
                      <option value="3">March</option>
                      <option value="4">April</option>
                      <option value="5">May</option>
                      <option value="6">June</option>
                      <option value="7">July</option>
                      <option value="8">August</option>
                      <option value="9">September</option>
                      <option value="10">October</option>
                      <option value="11">November</option>
                      <option value="12">December</option>
                    </select>
                  </div>
                  <div className="form-group">
                    <label>Year</label>
                    <select name="year" className="form-control" value={year} id="year" onChange={(e) => setYear(e.target.value)}>
                      <option value="2022">2022</option>
                      <option value="2023">2023</option>
                      <option value="2024">2024</option>
                      <option value="2025">2025</option>
                      <option value="2026">2026</option>
                      <option value="2027">2027</option>
                      <option value="2028">2028</option>
                      <option value="2029">2029</option>
                      <option value="2030">2030</option>
                      <option value="2031">2031</option>
                      <option value="2032">2032</option>
                      <option value="2033">2033</option>
                    </select>
                  </div>
                </div>
              }
            </div>
            {loading &&
              <Progress label='Compiling Data'/>
            }
            {exportFile &&
              <div className='row'>
                <div className='col-md-12'>
                  <a href={global.config.asset_server + exportFile} className={'btn btn-icon-split btn-lg btn-'+theme()} download target="_blank">
                    <span className="icon text-white-50">
                      <i className="fas fa-download"></i>
                    </span>
                    <span className="text">Download</span>
                  </a>
                </div>
              </div>
            }
          </div>
        </div>
        {userRole != 10 && 
        <div className="modal fade" id="exportModal" tabIndex="-1" role="dialog" aria-labelledby="export-popup" aria-hidden="true">
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="export-popup">Export</h5>
                <button className="close" type="button" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="form-group">
                  <label>Enter OTP to continue.</label>
                  <input type="text" maxLength="50" name="otp" value={otp} className="form-control" id="otp" placeholder="Enter OTP" onChange={(e) => setOtp(e.target.value)}/>
                </div>
              </div>
              <div className="modal-footer">
                <button className="btn btn-default" type="button" data-dismiss="modal">Cancel</button>
                <button className={'btn ml-3 btn-'+theme()} onClick={(e) => handleSubmit(e)}  data-dismiss="modal">Submit</button>
              </div>
            </div>
          </div>
        </div>
        }
      </div>
    )
}

export default Export;