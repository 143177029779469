import React, {useState, useEffect, useRef} from 'react';
import { useParams } from 'react-router';
import {Link, useNavigate  } from 'react-router-dom';
import axios from 'axios';
import '../config.js';

import { theme } from '../services/helper.js';
import { alertService } from '../services/alert.service.js';
import Progress from '../components/progress.js';
import TemplateBasicInfo from '../components/templateBasicInfo.js';

function Template(props) {
  const { id, type } = useParams();
  const [template, setTemplate] = useState({});
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(()=>{
    axios.get(global.config.api_server+'template?id='+id+'&token='+localStorage.getItem("token")) 
    .then(res => {
      if(res.status === 200 && res.data.status === "success"){
        setTemplate(res.data.template);
        setLoading(false);
      }else{
        alertService.error(res.data.error);
      }
    }).catch(err => {
      alertService.error(err);
      navigate("/template/"+type);
    });
  }, []);

  const navigateBack = () => {
    navigate("/template/"+type);
  }

  if(loading){
    return <Progress/>;
  }else{
    return(
      <div className="container-fluid">
        <div className="mb-3">
          <button onClick={(e) => navigateBack(e)} className="btn btn-light btn-icon-split mr-2">
            <span className="icon text-white-50">
              <i className="fas fa-arrow-left"></i>
            </span>
            <span className="text">Back</span>
          </button>
        </div>
        
        <TemplateBasicInfo id={id} type={type} template={template}></TemplateBasicInfo>
        
      </div>
    )
  }
}

export default Template;