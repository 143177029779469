import React, {useState, useEffect} from 'react';
import { createRoot } from 'react-dom/client';
import './index.scss';
import axios from 'axios';
import Base from './pages/base.js';
import Login from './pages/login.js';
import './config.js';
import { theme } from './services/helper.js';

function AuthHandler(){
  let showcase = <Login/>;
  if(window.location.pathname.indexOf("public") > -1 || (localStorage.getItem("token") && localStorage.getItem("token") != "undefined")){
    showcase = <Base/>;
  }
  
  function handleLogout(){
    axios.get(global.config.api_server+'logout?token='+localStorage.getItem("token"));
    const currentTheme = localStorage.getItem("theme");
    localStorage.clear();
    localStorage.setItem("theme", currentTheme);
    window.location.reload();
  }
  
  return(
      <div id="wrapper" className={'theme-'+theme()}>
        {showcase}
        <div className="modal fade" id="logoutModal" tabIndex="-1" role="dialog" aria-labelledby="logout-popup" aria-hidden="true">
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="logout-popup">Ready to Leave?</h5>
                <button className="close" type="button" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">Select "Logout" below if you are ready to end your current session.</div>
              <div className="modal-footer">
                <button className="btn btn-default" type="button" data-dismiss="modal">Cancel</button>
                <button className={'btn btn-'+theme()} onClick={handleLogout} data-dismiss="modal">Logout</button>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
}

const container = document.getElementById('showcase');
const root = createRoot(container);
root.render(<AuthHandler />);