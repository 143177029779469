import React, {useState, useEffect, useRef} from 'react';
import axios from 'axios';
import '../config.js';
import RichTextEditor from '../components/rte.js';
import { theme, createFormData } from '../services/helper.js';
import { alertService } from '../services/alert.service.js';
import Progress from '../components/progress.js';

function FestivalBasicInfo(props){
  const [loading, setLoading] = useState(false);
  const [description, setDescription] = useState(props.festival.description);
  const [subject, setSubject] = useState(props.festival.subject);
  const [festivalDate, setFestivalDate] = useState(props.festival.festival_date);
  const [status, setStatus] = useState(props.festival.status);
  const [file, setFile] = useState(null);
  const [otp, setOtp] = useState(null);
  const [templateId, setTemplateId] = useState(props.festival.template_id);
  const [sms, setSms] = useState(props.festival.sms);
  const userRole = localStorage.getItem("role");
  
  const handleSubmit = (event) => {
    event.preventDefault();
    if(subject && description && festivalDate && status && (userRole == 10 || otp)){
      setLoading(true);
      const formData = new FormData;
      if(file != null && file != undefined){
        formData.append('file', file);
        formData.append('file_name', file.name);
      }
      formData.append('description', description);
      formData.append('festival_date', festivalDate);
      formData.append('status', status);
      formData.append('subject', subject);
      formData.append('task', "edit");
      formData.append('id', props.id);
      formData.append('otp', otp);
      formData.append('template_id', templateId);
      formData.append('sms', sms);
      formData.append('token', localStorage.getItem("token"));
      setLoading(true);
      axios.post(global.config.api_server+'festival',formData).then(res => {
        if(res.status === 200 && res.data.status === "success"){
          alertService.success('Festival updated');
          setTimeout(function(){
            window.location.reload();
          }, 1000);
        }else{
          alertService.error(res.data.error);
          setLoading(false);
        }
      }).catch(err => {
        alertService.error(err);
        setLoading(false);
      });
    }else{
      alertService.error('Some fields are required');
    }
  }

  const reqPermissionOtp = (event) => {
    if(userRole < 10){
      const payloadType = "INVOICE_UPDATE";
      axios.get(global.config.api_server+'permission?payloadType='+payloadType+'&payload='+props.id+'&description='+props.festival.festival_id+' of '+props.festival.client_info.email+'&token='+localStorage.getItem("token")).then(res => {
        if(res.status === 200 && res.data.status === "success"){
          alertService.success('OTP Requested');
        }else{
          alertService.error(res.data.error);
        }
      }).catch(err => {
        alertService.error(err);
      });
    }
  }

  let showcase = "No attachment found";
  if(props.festival.uri){
    showcase = <a href={global.config.asset_server + props.festival.uri} className={'btn btn-icon-split btn-'+theme()}>
                  <span className="icon text-white-50">
                    <i className="fas fa-download"></i>
                  </span>
                  <span className="text">Download</span>
                </a>
    if(props.festival.uri.toUpperCase().endsWith('.JPG') || props.festival.uri.toUpperCase().endsWith('.JPEG') || props.festival.uri.toUpperCase().endsWith('.PNG') || props.festival.uri.toUpperCase().endsWith('.GIF')){
      showcase = <a href={props.festival.uri}>
                    <img src={global.config.asset_server + props.festival.uri} width="100%" height="auto"/>
                  </a>
    }
  }

  const date = new Date(festivalDate);
  let festival_date = undefined;
  if(date.getFullYear() > 1900){
    festival_date = date.getFullYear() + "-";
    if((date.getMonth() + 1) < 10){
      festival_date += "0";
    }
    festival_date += (date.getMonth() + 1);
    festival_date += "-";
    if(date.getDate() < 10){
      festival_date += "0";
    }
    festival_date += date.getDate();
  }
  const statuses = global.config.status.split(",");
  return(
    <div className="card shadow mb-4">
      <div className="card-header py-3">
        {userRole == 10 &&        
          <button onClick={(e) => handleSubmit(e)} className={'btn btn-icon-split float-right btn-'+theme()}>
            <span className="icon text-white-50">
              <i className="fas fa-save"></i>
            </span>
            <span className="text">Save</span>
          </button>
        }
        {userRole != 10 && 
          <button className={'btn btn-icon-split float-right btn-'+theme()} data-toggle="modal" data-target="#updateFestivalModal" onClick={reqPermissionOtp}>
            <span className="icon text-white-50">
              <i className="fas fa-save"></i>
            </span>
            <span className="text">Save</span>
          </button>
        }
        <h6 className={'mt-2 font-weight-bold text-'+theme()}>Festival Detail</h6>
      </div>
      <div className="card-body">
        <div className="row">
          {loading === true ? (
            <Progress label='Saving' small={true}/>
          ):null}
          <div className="col-md-6">
            <div className="form-group">
              <label>Subject *</label>
              <input type="text" value={subject} maxLength="100" name="subject" className="form-control" id="subject" placeholder="Enter Subject" onChange={(e) => setSubject(e.target.value)}/>
            </div>
            <div className="form-group">
              <label>Date *</label>
              <input type="date" value={festival_date} name="festival_date" className="form-control" id="festival_date" placeholder="Enter Date" onChange={(e) => setFestivalDate(e.target.value)}/>
            </div>
            <div className="form-group">
              <label>SMS Template Id</label>
              <input type="text" value={templateId} maxLength="100" name="templateId" className="form-control" id="templateId" placeholder="Enter SMS Template Id" onChange={(e) => setTemplateId(e.target.value)}/>
            </div>
            <div className="form-group">
              <label>Status</label>
              <select name="status" className="form-control" value={status} id="status" onChange={(e) => setStatus(e.target.value)}>
                {statuses.map(d =>
                    <option value={d} key={d}>{d}</option>
                )}
              </select>
            </div>
            <div className="form-group">
              <label>Attachment</label>
              <input type="file" maxLength="50" name="file" className="form-control" id="file" placeholder="Upload File" onChange={(e) => setFile(e.target.files[0])}/>
            </div>
            {showcase}
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label>SMS Body</label>
              <textarea type="text" value={sms} maxLength="2000" name="sms" className="form-control textarea-xlg" id="sms" placeholder="Enter SMS Body" onChange={(e) => setSms(e.target.value)}></textarea>
            </div>
            <div className="form-group">
              <label>Mail Body - <small>variable to be used : {global.config.variable_list_festival}</small></label>
              <RichTextEditor id={'festival-description-'+props.festival.festival_id} label={'Mail Body'} value={description} handleSubmit={(e)=>setDescription(e)}/>
            </div>
          </div>
        </div>
      </div>
      {userRole != 10 && 
        <div className="modal fade" id="updateFestivalModal" tabIndex="-1" role="dialog" aria-labelledby="update-festival-popup" aria-hidden="true">
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="update-festival-popup">Updating Festival</h5>
                <button className="close" type="button" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="form-group">
                  <label>Enter OTP to continue.</label>
                  <input type="text" maxLength="50" name="otp" value={otp} className="form-control" id="otp" placeholder="Enter OTP" onChange={(e) => setOtp(e.target.value)}/>
                </div>
              </div>
              <div className="modal-footer">
                <button className="btn btn-default" type="button" data-dismiss="modal">Cancel</button>
                <button className={'btn ml-3 btn-'+theme()} onClick={(e) => handleSubmit(e)}  data-dismiss="modal">Save</button>
              </div>
            </div>
          </div>
        </div>
        }
    </div>
  )
}

export default FestivalBasicInfo;