import React, {useState, useEffect, useRef} from 'react';
import axios from 'axios';
import '../config.js';

import { theme, createFormData } from '../services/helper.js';
import { alertService } from '../services/alert.service.js';
import Progress from '../components/progress.js';
import DocumentListItem from '../components/documentListItem.js';
import { Paginator } from '../components/paginator.js';

function ClientDocumentInfo(props){
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [filteredDocuments, setFilteredDocuments] = useState(props.client.documents);
  const [offset, setOffset] = useState(0);
  const [documents, setDocuments] = useState(props.client.documents);
  const [file, setFile] = useState(null);
  const [label, setLabel] = useState('');
  const [docType, setDocType] = useState('Photo');

  let searchForm = useRef();

  const handleSearch = (event) => {
    const target = event.target;
    const value = target.value;
    const filtered = [];
    for(let i = 0;i<documents.length;i++){
      if(value === undefined || value.trim() === '' || documents[i].label.indexOf(value) >= 0 || documents[i].type.indexOf(value) >= 0){
        filtered.push(documents[i]);
      }
    }
    setFilteredDocuments(filtered);
  }

  const handleSubmit = (event) =>{
    event.preventDefault();
    if(label && file && docType){
      const formData = new FormData;
      formData.append('file', file);
      formData.append('file_name', file.name);
      formData.append('label', label);
      formData.append('task', "upload");
      formData.append('client', props.id);
      formData.append('type', docType);
      formData.append('token', localStorage.getItem("token"));
      setLoading(true);
      axios.post(global.config.api_server+'document',formData).then(res => {
        if(res.status === 200 && res.data.status === "success"){
          alertService.success('Document uploaded');
          setTimeout(function(){
            window.location.reload();
          }, 1000);
        }else{
          alertService.error(res.data.error);
          setLoading(false);
        }
      }).catch(err => {
        alertService.error(err);
        setLoading(false);
      });
    }else{
      alertService.error('All the fields are required');
      setLoading(false);
    }
  }

  const resetSearch = (event) => {
    searchForm.current.reset();
    setSearch('');
    setFilteredDocuments(documents);
  }

  const onPageChange = (index_start) => {
    setOffset(index_start);
  }  

  let uploadDocument = "";
  if(parseInt(localStorage.getItem("role")) >= 3){
    uploadDocument = <a href="#" className={'btn btn-icon-split float-right btn-'+theme()} data-toggle="modal" data-target="#createDocumentModal">
                  <span className="icon text-white-50">
                    <i className="fas fa-archive"></i>
                  </span>
                  <span className="text">Add New Document</span>
                </a>;
  }
  let total = 0;
  let counter = 0;
  let offsetCounter = 0;
  const paginated_documents = [];
  for (const u of filteredDocuments) {
    total++;
    offsetCounter++;
    if (offsetCounter > offset && counter < global.config.items_per_page){
      counter++;
      paginated_documents.push(u);
    }
  }
  const docTypes = global.config.docType.split(",");
  return(
    <div>
      <div className="card shadow mb-4">
        <div className="card-header py-3">
          {uploadDocument}
          <form ref={searchForm} className="form-inline">
              <h6 className={'form-group mr-3 font-weight-bold text-'+theme()}>Client - Document(s)</h6>
              <div className="form-group mr-3">
                <input type="search" label="search" className="form-control" placeholder="Search" onChange={handleSearch}/>
              </div>
              {search &&
              <div className="form-group mr-3">
                <a href="#" className="btn btn-default btn-icon-split" onClick={resetSearch}>
                  <span className="icon">
                    <i className="fas fa-times"></i>
                  </span>
                </a>
              </div>
              }
            </form>
        </div>
        <div className="card-body">
          <div className="table-responsive">
            <div className="row">
            {loading === true ? (
              <Progress label='Loading..' small={true}/>
            ):null}
              <div className="col-sm-12">
                <table className="table table-bordered" width="100%">
                  <thead>
                    <tr>
                      <th>Showcase</th>
                      <th>Label</th>
                      <th>Type</th>
                      <th>Uploaded On</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {paginated_documents.map(doc =>
                      <DocumentListItem info={doc} key={doc.document_id}/>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            <Paginator
                itemCount={total}
                itemsPerPage={global.config.items_per_page}
                showNextPages={2}
                showPrevPages={2}
                filterTotal={filteredDocuments.length}
                grandTotal={documents.length}
                onClick={onPageChange.bind(this)}
              />
          </div>
        </div>
      </div>
      <div className="modal fade" id="createDocumentModal" tabIndex="-1" role="dialog" aria-labelledby="create-popup" aria-hidden="true">
        <div className="modal-dialog modal-xl" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="create-popup">Add new document</h5>
              <button className="close" type="button" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <form>
              <div className="modal-body">
                <div className="form-group">
                  <label>Label</label>
                  <input type="text" value={label} maxLength="50" name="label" className="form-control" id="label" placeholder="Enter Label" onChange={(e) => setLabel(e.target.value)}/>
                </div>
                <div className="form-group">
                  <label>Document Type</label>
                  <select name="filter" className="form-control" onChange={(e) => setDocType(e.target.value)}>
                  {docTypes.map(d =>
                    <option value={d} key={d}>{d}</option>
                  )}
                  </select>
                </div>
                <div className="form-group">
                  <label>File</label>
                  <input type="file" maxLength="50" name="file" className="form-control" id="file" placeholder="Upload File" onChange={(e) => setFile(e.target.files[0])}/>
                </div>
                {loading === true ? (
                  <Progress label='Uploading..' small={true}/>
                ):null}
              </div>
              <div className="modal-footer">
                <button className="btn btn-default" type="reset" data-dismiss="modal">Close</button>
                <button className={'btn btn-'+theme()} type="button" onClick={handleSubmit}>Submit</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ClientDocumentInfo;