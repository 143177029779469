import React, {useState, useEffect, useRef} from 'react';
import { useParams } from 'react-router';
import {Link, useNavigate} from 'react-router-dom';
import axios from 'axios';
import '../config.js';

import { theme, formData } from '../services/helper.js';
import { alertService } from '../services/alert.service.js';
import ClientBasicInfo from '../components/clientBasicInfo.js';
import ClientDocumentInfo from '../components/clientDocumentInfo.js';
import ClientInvoiceInfo from '../components/clientInvoiceInfo.js'
import ClientReceiptInfo from '../components/clientReceiptInfo.js'
import ClientCreditInfo from '../components/clientCreditInfo.js'
import Progress from '../components/progress.js';

function Client(props) {
  const { id, subnav } = useParams();
  const [client, setClient] = useState({});
  const [loading, setLoading] = useState(true);
  const [otp, setOtp] = useState(null);
  const navigate = useNavigate();
  const userRole = localStorage.getItem("role");

  useEffect(()=>{
    axios.get(global.config.api_server+'client?id='+id+'&token='+localStorage.getItem("token")) 
    .then(res => {
      if(res.status === 200 && res.data.status === "success"){
        setClient(res.data.client);
        setLoading(false);
      }else{
        alertService.error(res.data.error);
      }
    }).catch(err => {
      alertService.error(err);
      navigate("/clients");
    });
  }, []);
  
  const handleDelete = (event) => {
    event.preventDefault();
    if(id && (otp || userRole == 10)){
      axios.get(global.config.api_server+'client?id='+id+'&task=delete&otp='+otp+'&token='+localStorage.getItem("token")).then(res => {
        if(res.status === 200 && res.data.status === "success"){
          alertService.success('Client deleted');
          navigate("/clients");
        }else{
          alertService.error(res.data.error);
        }
      }).catch(err => {
        alertService.error(err);
      });
    }else{
      alertService.error('Invalid Input');
    }
  }

  const reqPermissionOtp = (event) => {
    if(userRole < 10){
      const payloadType = "CLIENT_DELETE";
      axios.get(global.config.api_server+'permission?payloadType='+payloadType+'&payload='+id+'&description='+client.email+'&token='+localStorage.getItem("token")).then(res => {
        if(res.status === 200 && res.data.status === "success"){
          alertService.success('OTP Requested');
        }else{
          alertService.error(res.data.error);
        }
      }).catch(err => {
        alertService.error(err);
      });
    }
  }
  
  const checkSubNav = (param) => {
    let design = "";
    if(subnav === param){
      design = " disabled";
    }else if(param === 'basic' && (subnav === null || subnav === undefined)){
      design = " disabled";
    }
    return design;
  }

  let subnavItem = <ClientBasicInfo id={id} client={client}></ClientBasicInfo>;
  if(subnav === 'document'){
    subnavItem = <ClientDocumentInfo id={id} client={client}></ClientDocumentInfo>;
  }else if(subnav === 'invoice'){
    subnavItem = <ClientInvoiceInfo id={id} client={client}></ClientInvoiceInfo>;
  }else if(subnav === 'receipt'){
    subnavItem = <ClientReceiptInfo id={id} client={client}></ClientReceiptInfo>;
  }else if(subnav === 'credit'){
    subnavItem = <ClientCreditInfo id={id} client={client}></ClientCreditInfo>;
  }
  if(loading){
    return <Progress/>;
  }else{
    return(
      <div className="container-fluid">
        <div className="mb-3">
          <button className={'btn btn-icon-split float-right btn-'+theme()} data-toggle="modal" data-target="#deleteClientModal" onClick={reqPermissionOtp}>
            <span className="icon text-white-50">
              <i className="fas fa-trash"></i>
            </span>
            <span className="text">Delete</span>
          </button>
          <Link to={"/clients"} className="btn btn-light btn-icon-split mr-2">
            <span className="icon text-white-50">
              <i className="fas fa-arrow-left"></i>
            </span>
            <span className="text">Back</span>
          </Link>
          <Link to={"/client/"+id} className={'btn btn-icon-split mr-2 btn-'+theme() + checkSubNav('basic')}>
            <span className="icon text-white-50">
              <i className="fas fa-user"></i>
            </span>
            <span className="text">Basic Info</span>
          </Link>
          <Link to={"/client/"+id+"/document"} className={'btn btn-icon-split mr-2 btn-'+theme() + checkSubNav('document')}>
            <span className="icon text-white-50">
              <i className="fas fa-archive"></i>
            </span>
            <span className="text">Documents</span>
          </Link>
          <Link to={"/client/"+id+"/invoice"} className={'btn btn-icon-split mr-2 btn-'+theme() + checkSubNav('invoice')}>
            <span className="icon text-white-50">
              <i className="fas fa-clipboard"></i>
            </span>
            <span className="text">Proforma Invoices</span>
          </Link>
          <Link to={"/client/"+id+"/receipt"} className={'btn btn-icon-split mr-2 btn-'+theme() + checkSubNav('receipt')}>
            <span className="icon text-white-50">
              <i className="fas fa-file"></i>
            </span>
            <span className="text">Receipts</span>
          </Link>
          <Link to={"/client/"+id+"/credit"} className={'btn btn-icon-split mr-2 btn-'+theme() + checkSubNav('credit')}>
            <span className="icon text-white-50">
              <i className="fas fa-credit-card"></i>
            </span>
            <span className="text">Credit Notes</span>
          </Link>
        </div>
        
        {subnavItem}
        
        <div className="modal fade" id="deleteClientModal" tabIndex="-1" role="dialog" aria-labelledby="delete-popup" aria-hidden="true">
          <div className="modal-dialog modal-xl" role="document">
          {userRole == 10 && 
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="delete-client-popup">Confirm deletion?</h5>
                <button className="close" type="button" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">Are you sure, you want to delete this client?</div>
              <div className="modal-footer">
                <button className="btn btn-default" type="button" data-dismiss="modal">Cancel</button>
                <button className={'btn ml-3 btn-'+theme()} onClick={handleDelete} data-dismiss="modal">Delete</button>
              </div>
            </div>
          }
          {userRole != 10 && 
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="delete-client-popup">Confirm deletion?</h5>
                <button className="close" type="button" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="form-group">
                  <label>Are you sure, you want to delete this client? Enter OTP to continue.</label>
                  <input type="text" maxLength="50" name="otp" value={otp} className="form-control" id="otp" placeholder="Enter OTP" onChange={(e) => setOtp(e.target.value)}/>
                </div>
              </div>
              <div className="modal-footer">
                <button className="btn btn-default" type="button" data-dismiss="modal">Cancel</button>
                <button className={'btn ml-3 btn-'+theme()} onClick={handleDelete} data-dismiss="modal">Delete</button>
              </div>
            </div>
          }
          </div>
        </div>
      </div>
    )
  }
}

export default Client;